import { Button, Card, CardContent, Divider, Grid, Link, Typography, useMediaQuery } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { theme } from "@racwa/react-components";
import { colors } from "@racwa/styles";
import { QuotePremium, useFormotiv } from "raci-react-library";
import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useRecoilState } from "recoil";
import { FormotivQuoteNumber, formotivQuoteNumber } from "../../shared/atoms";
import { FORMOTIV_CONFIG } from "../../shared/constants";
import EmailQuoteForm from "./components/EmailQuoteForm";
import RacWaPaymentAdjustment from "./components/PaymentAdjustment";
import RacWaWhatsIncluded from "./components/WhatsIncluded";
import { StyledTypography } from "./styled";
import { FormProps } from "./types";

export const Form: React.FC<FormProps> = ({
  form,
  emailForm,
  quote,
  defaultFrequency,
  showRacingCover,
  showLowerExcessMessage,
  tooltipData,
  onSubmit,
  onQuoteCustomisation,
  setYourQuoteFrequency,
  onEmail,
}) => {
  const { handleSubmit, watch } = form;
  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);

  const [hasQuoteNumberBeenPreviouslyPushedToFormotiv, setPushQuoteNumberState] =
    useRecoilState<FormotivQuoteNumber>(formotivQuoteNumber);

  const paymentFrequency = watch("paymentFrequency");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (window.formotiv && !hasQuoteNumberBeenPreviouslyPushedToFormotiv.hasBeenPushed) {
      window.formotivConfig.state.ptv1 = quote.quoteNumber;
      setPushQuoteNumberState({ hasBeenPushed: true });
    }
  }, [quote, hasQuoteNumberBeenPreviouslyPushedToFormotiv.hasBeenPushed, setPushQuoteNumberState]);

  useEffect(() => {
    setYourQuoteFrequency?.(paymentFrequency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentFrequency]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2" id="header" data-testid="header">
          Here's your quote
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          <span style={{ fontWeight: 400, color: "#425563" }}>Your quote number is</span>{" "}
          <span style={{ fontWeight: 700, color: "#425563" }} id="quoteNumber">
            {quote.quoteNumber}
          </span>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card variant="outlined">
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(formotivOnSubmitWrapper(onSubmit))} action="#">
              <QuotePremium
                id="payment-frequency"
                name="paymentFrequency"
                premiumDetails={quote.cover?.premiumDetails!}
                defaultFrequency={defaultFrequency}
                isMobile={isMobile}
                quoteHeading={
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={10}>
                      <Typography variant="h3">Your boat insurance</Typography>
                    </Grid>
                  </Grid>
                }
                discounts={[]}
              />
              <Divider />
              <CardContent sx={{ backgroundColor: colors.racGrayLight }}>
                <RacWaPaymentAdjustment
                  quote={quote}
                  showRacingCover={showRacingCover}
                  tooltipData={tooltipData}
                  showLowerExcessMessage={showLowerExcessMessage}
                  onQuoteCustomisation={onQuoteCustomisation}
                />
              </CardContent>
              <Divider />
              <CardContent data-testid="policy-button-card" sx={{ paddingBottom: "0px !important" }}>
                <Button type="submit" data-testid="submit" color="primary" variant="contained" fullWidth>
                  Get policy
                </Button>
              </CardContent>
            </form>
          </FormProvider>
          <FormProvider {...emailForm}>
            <form noValidate action="#" onSubmit={emailForm.handleSubmit(onSubmit)}>
              <CardContent data-testid="email-button-card" sx={{ paddingTop: "0px" }}>
                <EmailQuoteForm onSubmit={onEmail} quoteNumber={quote.quoteNumber} />
              </CardContent>
            </form>
          </FormProvider>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <RacWaWhatsIncluded />
      </Grid>
      <Grid item xs={12}>
        <StyledTypography id="terms-1" data-testid="terms-1" variant="body2">
          Please remember that this is a quote only and does not provide cover. The final information you provide to us
          may affect the terms and premium we offer.
        </StyledTypography>
      </Grid>
      <Grid item xs={12}>
        <StyledTypography id="terms-2" data-testid="terms-2" variant="body2">
          It's important that you read and understand the{" "}
          <Link
            href={process.env.REACT_APP_PDS_PED_URL}
            rel="noreferrer noopener"
            target="_blank"
            onClick={() => gtm(event("Product Disclosure Statement"))}
          >
            Product Disclosure Statement (PDS)
          </Link>{" "}
          to ensure the cover meets your needs. Please contact us if you have any difficulty understanding the PDS or
          your quote.
        </StyledTypography>
      </Grid>
    </Grid>
  );
};

export default Form;
