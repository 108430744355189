import { useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useState } from "react";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { ApiException, PoliciesRequest } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { PurchaseRoutes } from "../../../../shared/routing/routes.config";
import { YourDetailsState } from "../../../YourDetails/types";
import { PaymentData } from "../../types";

export interface OnSubmitBankAccountEvent extends PoliciesRequest {}

export interface UseBankAccountResults {
  isError: boolean;
  onSubmit: (event: OnSubmitBankAccountEvent) => void | Promise<void>;
}

export const useBankPayment = (): UseBankAccountResults => {
  const [isError, setIsError] = useState(false);
  const apiClient = useApiClient();
  const [, setPaymentData] = useSessionState<PaymentData>({
    specificKey: PurchaseRoutes.Payment,
    skipPageTrackingRecalculation: true,
  });
  const memberDetailsState = useGetSessionState<YourDetailsState>(PurchaseRoutes.YourDetails);

  const setBackdrop = useSetBackdrop();

  const sanitizeAccountName = (accountName: string | undefined): string => {
    if (accountName) {
      accountName = accountName.replace(/—/g, "-");
      accountName = accountName.replace(/’/g, "'");
      accountName = removeTrailingApostrophe(accountName);
      return accountName.trim();
    }
    return "";
  };

  const removeTrailingApostrophe = (accountName: string): string => {
    while (accountName.substr(accountName.length - 1) === "'") {
      accountName = accountName.substring(0, accountName.length - 1);
    }
    return accountName;
  };

  const onSubmitBankAccount = async (event: OnSubmitBankAccountEvent): Promise<void> => {
    await payWithBankAccount({
      bsb: event.bsb?.trim(),
      accountNumber: event.accountNumber?.trim(),
      accountName: event.accountName?.trim(),
      frequency: event.frequency,
      method: event.method,
    } as PoliciesRequest);
  };

  const payWithBankAccount = async (paymentRequest: PoliciesRequest) => {
    setBackdrop(true);
    setIsError(false);

    try {
      paymentRequest.accountName = sanitizeAccountName(paymentRequest.accountName);

      const { result } = await apiClient.policies(paymentRequest);

      if (result.policyNumber) {
        setPaymentData({
          policyNumber: result.policyNumber,
          receiptNumber: result.receiptNumber,
          firstName: memberDetailsState.firstName,
          isSuccessful: true,
          isCompleted: true,
          lockdownPreviousPage: true,
        });
      }
      setIsError(true);
    } catch (exception) {
      const errorResponse = exception as ApiException;
      if (errorResponse.status >= 500) {
        setIsError(true);
      }
    } finally {
      setBackdrop(false);
    }
  };

  return {
    isError,
    onSubmit: onSubmitBankAccount,
  };
};

export default useBankPayment;
