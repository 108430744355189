import { Grid, Typography } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { YesNoButtonGroup, trackCustomFormotivInput, useFormotiv } from "raci-react-library";
import { FormProvider, useWatch } from "react-hook-form";
import { CallToActionButton } from "../../shared/components/CallToActionButton";
import FaqCard from "../../shared/components/FaqCard";
import { FORMOTIV_CONFIG } from "../../shared/constants";
import { getYesNoLabel } from "../../shared/utils/helpers";
import { RegistrationInput } from "./components/RegistrationInput";
import { FormProps } from "./types";

const knowBoatRegistrationName = "knowBoatRegistration";
const knowTrailerRegistrationName = "knowTrailerRegistration";
const boatRegoName = "boatRego";
const trailerRegoName = "trailerRego";

export const Form: React.FC<FormProps> = ({ form, onSubmit }) => {
  const { handleSubmit } = form;
  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);

  const isBoatRegoKnown = useWatch({ control: form.control, name: "knowBoatRegistration" });
  const isTrailerRegoKnown = useWatch({ control: form.control, name: "knowTrailerRegistration" });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(formotivOnSubmitWrapper(onSubmit))} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2" id="header" data-testid="header">
              Your registration
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "8px" }}>
          <YesNoButtonGroup
            name={knowBoatRegistrationName}
            label="Do you know your boat's registration?"
            id={knowBoatRegistrationName}
            data-testid={knowBoatRegistrationName}
            onChange={(_, value) =>
              trackCustomFormotivInput(getYesNoLabel(knowBoatRegistrationName, value), "button", value)
            }
          />
        </Grid>
        {isBoatRegoKnown ? (
          <Grid item xs={12}>
            <RegistrationInput
              id="boat-rego"
              label="Boat rego"
              fieldName={boatRegoName}
              errorMessage="Please enter a boat registration"
              onBlur={() => gtm(fieldTouched("Boat rego"))}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} sx={{ marginTop: "8px" }}>
          <YesNoButtonGroup
            name={knowTrailerRegistrationName}
            label="Do you know your trailer's registration?"
            id={knowTrailerRegistrationName}
            data-testid={knowTrailerRegistrationName}
            onChange={(_, value) =>
              trackCustomFormotivInput(getYesNoLabel(knowTrailerRegistrationName, value), "button", value)
            }
          />
        </Grid>
        {isTrailerRegoKnown ? (
          <Grid item xs={12}>
            <RegistrationInput
              id="trailer-rego"
              label="Trailer rego"
              fieldName={trailerRegoName}
              errorMessage="Please enter a trailer registration"
              onBlur={() => gtm(fieldTouched("Trailer rego"))}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} sx={{ marginTop: "8px" }}>
          <CallToActionButton type="submit" data-testid="submit" color="primary" variant="contained" fullWidth>
            Next
          </CallToActionButton>
          <FaqCard />
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Form;
