import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { boatMakesAtom, claimTypesAtom, financiersAtom, hullTypesAtom, motorTypesAtom, suburbsAtom } from "../../atoms";
import { UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL } from "../../routing/routes.config";
import useApiClient from "../useApiClient";

export const useLoadReferenceData = (shieldEnvironment: string = "") => {
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const setClaimTypes = useSetRecoilState(claimTypesAtom);
  const setMotorTypes = useSetRecoilState(motorTypesAtom);
  const setHullTypes = useSetRecoilState(hullTypesAtom);
  const setSuburbs = useSetRecoilState(suburbsAtom);
  const setFinanciers = useSetRecoilState(financiersAtom);
  const setBoatMakes = useSetRecoilState(boatMakesAtom);

  const redirectToSystemUnavailable = () => {
    navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL, { replace: true });
  };

  useEffect(() => {
    let isMounted = true;

    const loadReferenceData = async () => {
      try {
        const { result } = await apiClient.getreferencedata();
        if (isMounted) {
          setClaimTypes(result.claimTypes);
          setMotorTypes(result.motorTypes);
          setHullTypes(result.hullTypes);
          setFinanciers(result.financiers);
          setBoatMakes(result.boatMakes);
          setSuburbs(result.suburbs);
        }
      } catch {
        redirectToSystemUnavailable();
      }
    };

    loadReferenceData();

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shieldEnvironment]);
};

export default useLoadReferenceData;
