import styled from "@emotion/styled";
import { Divider, Typography } from "@mui/material";
import { colors } from "@racwa/styles";

export const StyledTypography = styled(Typography)({
  color: colors.dieselDeep,
  fontWeight: 300,
  fontSize: "14px",
  lineHeight: "24px",
});

export const StyledDivider = styled(Divider)({
  marginTop: "20px",
});
