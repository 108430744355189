import { Divider, Grid, Typography } from "@mui/material";
import { InlineAccordion } from "raci-react-library";
import { useState } from "react";
import { MoreBoatPolicySummaryItem } from "./components/PolicySummaryItems/MoreBoatPolicySummaryItem";
import { RegistrationPolicySummaryItem } from "./components/PolicySummaryItems/RegistrationPolicySummaryItem";
import { StartDatePolicySummaryItem } from "./components/PolicySummaryItems/StartDatePolicySummaryItem";
import { YourBoatPolicySummaryItem } from "./components/PolicySummaryItems/YourBoatPolicySummaryItem";
import { YourCoverPolicySummaryItem } from "./components/PolicySummaryItems/YourCoverPolicySummaryItem";
import { YourDetailsPolicySummaryItem } from "./components/PolicySummaryItems/YourDetailsPolicySummaryItem";
import { StyledTypography } from "./styles";

export const PolicySummary: React.FC = () => {
  const [showQuoteInformation, setShowQuoteInformation] = useState(false);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h3" style={{ fontWeight: 600, fontSize: "18px" }}>
          Your policy summary
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <StyledTypography variant="body2" sx={{ paddingTop: "8px" }}>
          Please ensure all details are correct and that the policy suits your needs.
        </StyledTypography>
      </Grid>
      <Grid item xs={12}>
        <YourBoatPolicySummaryItem />
        {showQuoteInformation ? <Divider sx={{ marginTop: "20px" }} /> : null}
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "24px", marginBottom: "-12px" }}>
        <InlineAccordion
          title="Hide your quote information"
          titleClosed="Show your quote information"
          id="show-quote-information-accordion"
          data-testid="show-quote-information-accordion"
          isQuoteBreakdown={true}
          open={showQuoteInformation}
          onClick={() => setShowQuoteInformation(!showQuoteInformation)}
          alignCenter={true}
        />
      </Grid>
      {showQuoteInformation ? (
        <>
          <Grid item xs={12}>
            <YourCoverPolicySummaryItem />
          </Grid>
          <Grid item xs={12}>
            <MoreBoatPolicySummaryItem />
          </Grid>
          <Grid item xs={12}>
            <RegistrationPolicySummaryItem />
          </Grid>
          <Grid item xs={12}>
            <StartDatePolicySummaryItem />
          </Grid>
          <Grid item xs={12}>
            <YourDetailsPolicySummaryItem />
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

export default PolicySummary;
