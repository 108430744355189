import { Grid } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { fieldTouched, gtm } from "@racwa/analytics";
import { RacwaAutocomplete } from "@racwa/react-components";
import { YesNoButtonGroup, trackCustomFormotivInput } from "raci-react-library";
import { Controller, useFormContext } from "react-hook-form";
import { Financier } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { getYesNoLabel } from "../../../../shared/utils/helpers";
import { YourBoatState } from "../../types";

const boatFinanceLabel = "Who your boat is financed with";
const boatFinanceName = "financier";
const financeYesNoName = "financed";
const financeOnBoatLabel = "Finance on your boat";

const otherFinancier: Financier = {
  financierName: "Other",
  externalFinancierNumber: "591778800-501237709",
};

export interface BoatFinanceProps {
  options?: Financier[];
}

export const BoatFinance: React.FC<BoatFinanceProps> = ({ options = [] }) => {
  const { control, watch } = useFormContext<YourBoatState>();
  const financed = watch("financed");

  const otherValue = options.find((o) => o.financierName === "Other financier");

  // Remove any duplicates
  const uniqueOptions = Array.from(new Set(options.map((option) => option.financierName))).map((name) =>
    options.find((option) => option.financierName === name),
  );

  type TOptions = typeof uniqueOptions extends Array<infer T> ? T : never;
  const _filterOptions = createFilterOptions<TOptions>({ matchFrom: "start" });

  type TFilterOptions = React.ComponentProps<typeof RacwaAutocomplete<TOptions>>["filterOptions"];

  const filterOptions: TFilterOptions = (uniqueOptions, state) => {
    const results = _filterOptions(uniqueOptions, state);
    if (otherValue) {
      const index = results.indexOf(otherValue);
      if (index !== -1) {
        results[index] = otherFinancier;
      } else {
        results.push(otherFinancier);
      }
    }
    return results;
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <YesNoButtonGroup
          label={financeOnBoatLabel}
          id="finance-yes-no-group"
          name={financeYesNoName}
          onChange={(_, value) => trackCustomFormotivInput(getYesNoLabel(financeYesNoName, value), "button", value)}
        />
      </Grid>
      {financed && (
        <Grid item xs={12} sx={{ marginTop: "16px" }}>
          <Controller
            control={control}
            name={boatFinanceName}
            rules={{
              required: { value: true, message: "Please enter a financier" },
            }}
            render={({ field: { ref, value, onBlur, onChange: onComponentChange }, fieldState: { error } }) => (
              <RacwaAutocomplete
                id="boat-financier-autocomplete"
                label={boatFinanceLabel}
                sublabel="If your financier isn't listed, select 'Other'."
                placeholder="Please select one"
                options={uniqueOptions}
                getOptionLabel={(option) => option?.financierName ?? ""}
                isOptionEqualToValue={(option, value) =>
                  option?.externalFinancierNumber === value?.externalFinancierNumber
                }
                error={!!error}
                helperText={error?.message}
                value={value || null}
                fullWidth
                filterOptions={filterOptions}
                autoComplete
                autoHighlight
                clearOnEscape
                loading={uniqueOptions === undefined}
                onChange={(_, value) => {
                  trackCustomFormotivInput(boatFinanceName, "autocomplete", value);
                  onComponentChange && onComponentChange(value);
                }}
                onBlur={() => {
                  onBlur && onBlur();
                  gtm(fieldTouched(boatFinanceLabel));
                }}
                data-hj-suppress
                inputRef={ref}
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default BoatFinance;
