import { Grid } from "@mui/material";
import { useGetSessionState } from "raci-react-library";
import { useNavigate } from "react-router-dom";
import { PURCHASE_YOUR_REGISTRATION_PAGE_URL, PurchaseRoutes } from "../../../../../../../shared/routing/routes.config";
import { YourRegistrationState } from "../../../../../../YourRegistration/types";
import { StyledDivider, StyledTypography } from "../../../styles";
import SummaryItem from "../../SummaryItem";

export const RegistrationPolicySummaryItem: React.FC = () => {
  const registrationState = useGetSessionState<YourRegistrationState>(PurchaseRoutes.YourRegistration);
  const navigate = useNavigate();

  const getRego = (type: string, rego?: string) => {
    if (rego) {
      return `${type} rego ${rego}`;
    } else {
      return `No ${type.toLowerCase()} rego provided`;
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <SummaryItem
          title="Registration"
          name={PurchaseRoutes.YourRegistration}
          onClick={() => navigate(PURCHASE_YOUR_REGISTRATION_PAGE_URL)}
        >
          <StyledTypography
            variant="body2"
            id={`${PurchaseRoutes.YourRegistration}-boat-rego-text`}
            data-testid={`${PurchaseRoutes.YourRegistration}-boat-rego-text`}
          >
            {getRego("Boat", registrationState.boatRego)}
          </StyledTypography>
          <StyledTypography
            variant="body2"
            id={`${PurchaseRoutes.YourRegistration}-trailer-rego-text`}
            data-testid={`${PurchaseRoutes.YourRegistration}-trailer-rego-text`}
          >
            {getRego("Trailer", registrationState.trailerRego)}
          </StyledTypography>
        </SummaryItem>
        <StyledDivider />
      </Grid>
    </Grid>
  );
};
