import { Link, Typography } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { PaymentFrequency, useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { premiumDetailsState } from "../../../../shared/atoms";
import useApiClient from "../../../../shared/hooks/useApiClient";
import {
  ApiException,
  BoatType,
  EmailQuoteRequest,
  YourQuoteRequest,
} from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import {
  PURCHASE_START_DATE_PAGE_URL,
  QuoteRoutes,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
} from "../../../../shared/routing/routes.config";
import { BoatTypeState } from "../../../BoatType/types";
import { MoreBoatState } from "../../../MoreBoat/types";
import { YourBoatState } from "../../../YourBoat/types";
import { FormProps, YourQuoteState } from "../../types";

export const useYourQuote = (): FormProps => {
  const navigate = useNavigate();
  const setPremiumDetailsState = useSetRecoilState(premiumDetailsState);
  const location = useLocation();
  const apiClient = useApiClient();
  const setBackdrop = useSetBackdrop();
  const [moreBoatState, setMoreBoatState] = useSessionState<MoreBoatState>({
    specificKey: QuoteRoutes.MoreBoat,
    skipPageTrackingRecalculation: true,
  });
  const { selectedBoatType } = useGetSessionState<BoatTypeState>(QuoteRoutes.BoatType);
  const [state, setState] = useSessionState<YourQuoteState>();
  const [yourBoatState, setYourBoatState] = useSessionState<YourBoatState>({
    specificKey: QuoteRoutes.YourBoat,
    skipPageTrackingRecalculation: true,
  });
  const [showLowerExcessMessage, setShowLowerExcessMessage] = useState(false);

  const defaultValues = {
    paymentFrequency: state?.paymentFrequency || PaymentFrequency.Annual,
    excess: state?.excess || moreBoatState.quote.cover?.excess.value || 0,
    agreedValue: state?.agreedValue || 0,
    racingCover: state?.racingCover || false,
    flotationCover: state?.flotationCover || false,
  };

  const form = useForm<YourQuoteState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    shouldUnregister: true,
  });

  const emailForm = useForm<EmailQuoteRequest>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues,
  });

  const {
    trigger,
    formState: { isDirty, isValid, errors },
  } = form;

  const updateQuote = async (value: YourQuoteState) => {
    const quoteRequest: YourQuoteRequest = {
      agreedValue: value.agreedValue,
      excess: value.excess,
      flotationCover: value.flotationCover ?? false,
      racingCover: value.racingCover ?? false,
      frequency: value.paymentFrequency,
    };
    const quoteResponse = await apiClient.yourquote(quoteRequest);
    setMoreBoatState({ ...moreBoatState, quote: quoteResponse.result });
    setPremiumDetailsState(quoteResponse.result.cover?.premiumDetails);
  };

  const bypassExcess = () => {
    return errors.excess?.message === "Please select a lower excess" && !errors.agreedValue;
  };

  const onSubmit = async (value: YourQuoteState) => {
    try {
      setBackdrop(true);

      await updateQuote(value);
      setShowLowerExcessMessage(false);

      return new Promise<void>(() => {
        setState({ ...value, isCompleted: true });
        navigate(PURCHASE_START_DATE_PAGE_URL);
      });
    } catch (exception) {
      const error = exception as ApiException;
      if (error.status === 400) {
        setShowLowerExcessMessage(true);
      } else {
        navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL, {
          state: { referrer: location.pathname, exception: { request: "patch /your-quote", status: error.status } },
        });
      }
    } finally {
      setBackdrop(false);
    }
  };

  const onQuoteCustomisation = async (value: YourQuoteState, forceSave: boolean = false) => {
    await trigger();
    if (!isValid || (!forceSave && !isDirty)) {
      if (!bypassExcess()) {
        return;
      }
    }
    const loadingMessage = "Recalculating...";

    try {
      setBackdrop(true, loadingMessage);

      setYourBoatState({ ...yourBoatState, agreedValue: value.agreedValue });

      await updateQuote(value);
      setShowLowerExcessMessage(false);

      setState({ ...value, isCompleted: true });
    } catch (exception) {
      const error = exception as ApiException;
      if (error.status === 400) {
        setShowLowerExcessMessage(true);
      } else {
        navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL, {
          state: { referrer: location.pathname, exception: { request: "patch /your-quote", status: error.status } },
        });
      }
    } finally {
      setBackdrop(false, loadingMessage);
    }
  };

  const onEmail = async (value: EmailQuoteRequest) => {
    try {
      setBackdrop(true);
      await apiClient.emailquote({ ...value });
      gtm(event("Quote sent"));
      setState({ ...state, isCompleted: false });
    } catch {
      return false;
    } finally {
      setBackdrop(false);
    }
    return true;
  };

  const setYourQuoteFrequency = (value: PaymentFrequency) => {
    if (state.paymentFrequency !== value) {
      setState({ ...state, paymentFrequency: value });
    }
  };

  const excessTooltipProps = {
    title: "Excess",
    id: "excess-tooltip",
    testId: "excess-tooltip",
    message:
      "The excess is the amount you may need to pay towards settlement of any claim. If you adjust your excess, the amount of premium you pay will change.",
  };

  const agreedValueTooltipProps = {
    title: "Agreed value",
    id: "agreed-value-tooltip",
    testId: "agreed-value-tooltip",
    message:
      "The agreed value is the amount we agree to insure your boat for. It includes GST and registration. If you adjust the agreed value, the amount of premium you pay will change.",
  };

  const flotationTooltipProps = {
    title: "Waterskiing and flotation devices",
    id: "flotation-tooltip",
    testId: "flotation-tooltip",
    message: (
      <>
        <Typography variant="body2">
          <b>
            We'll cover your legal liability for accidental death or bodily injury to a water skier towed by your boat.
            We also cover damage to another person's property caused by the water skier. Waterskiing includes barefoot
            waterskiing, skurfing, wakeboarding and using a flotation device being towed by your boat (and only when
            using recognised and commercially manufactured equipment). Limits apply. See the{" "}
            <Link href={process.env.REACT_APP_PDS_PED_URL} rel="noreferrer noopener" target="_blank">
              Product Disclosure Statement
            </Link>{" "}
            for more information.
          </b>
        </Typography>
      </>
    ),
  };

  const racingCoverTooltipProps = {
    title: "Racing cover",
    id: "racing-tooltip",
    testId: "racing-tooltip",
    message: (
      <>
        <Typography variant="body2">
          <b>
            Racing cover insures your boat for loss or damage while you take part in an organised sailing competition
            with other boats over a designated distance or route.
          </b>
        </Typography>
        <p></p>
        <Typography variant="body2">
          <b>We don't cover:</b>
        </Typography>
        <ul>
          <li>
            <Typography variant="body2">
              <b>Any racing outside 200 nautical miles from the Australian coastline.</b>
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              <b>Participation in an ocean race that's more than 200 nautical miles from start to finish.</b>
            </Typography>
          </li>
        </ul>
      </>
    ),
  };

  const showRacingCover = selectedBoatType === BoatType.SailBoat;
  const { quote } = moreBoatState;

  return {
    form,
    emailForm,
    quote,
    defaultFrequency: state.paymentFrequency,
    showRacingCover,
    showLowerExcessMessage,
    tooltipData: {
      excessTooltip: excessTooltipProps,
      agreedValueTooltip: agreedValueTooltipProps,
      flotationTooltip: flotationTooltipProps,
      racingCoverTooltip: racingCoverTooltipProps,
    },
    onSubmit,
    onQuoteCustomisation,
    setYourQuoteFrequency,
    onEmail,
  };
};
