import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonProps } from "@mui/material";

export interface EmailThisQuoteButtonProps extends ButtonProps {
  open?: boolean | undefined;
}

export const EmailQuoteButton: React.FC<EmailThisQuoteButtonProps> = ({ open = false, ...props }) => {
  const emailToggleQuoteButtonId = `button-toggle-email-quote-form`;
  const icon = open ? faSortUp : faSortDown;
  const color = open ? "inherit" : "secondary";

  return (
    <Button
      type="button"
      id={emailToggleQuoteButtonId}
      data-testid={emailToggleQuoteButtonId}
      color={color}
      variant="contained"
      fullWidth
      endIcon={
        <FontAwesomeIcon
          icon={icon}
          style={{ fontSize: "1.25rem !important", position: "relative", top: open ? "5px" : "-4px" }}
        />
      }
      style={{ marginTop: `15px` }}
      {...props}
    >
      Email quote
    </Button>
  );
};

export default EmailQuoteButton;
