import { Typography } from "@mui/material";
import { useSessionState } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { QUOTE_ABOUT_YOU_PAGE_URL } from "../../../../shared/routing/routes.config";
import { FormProps, LetsStartState } from "../../types";

export const useLetsStart = (): FormProps => {
  const navigate = useNavigate();
  const [letsStartState, setLetsStartState] = useSessionState<LetsStartState>();

  const form = useForm<LetsStartState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: letsStartState,
    shouldUnregister: true,
  });

  const onSubmit = (values: LetsStartState) => {
    const letsStartForm = form.getValues();
    if (letsStartForm.waterStored) {
      form.setFocus("waterStored");
    }
    if (letsStartForm.commercialUse) {
      form.setFocus("commercialUse");
    }
    if (letsStartForm.personalWatercraft) {
      form.setFocus("personalWatercraft");
    }
    return new Promise<void>(() => {
      if (!(values.highValue || values.personalWatercraft || values.commercialUse || values.waterStored)) {
        setLetsStartState({ ...values, isCompleted: true });
        navigate(QUOTE_ABOUT_YOU_PAGE_URL);
      }
    });
  };

  const waterStoredTooltipProps = {
    title: "Stored in the water",
    testId: "waterStoredTooltip",
    message: (
      <Typography variant="body2">
        This is when your boat is secured to a fixed object such as a marina, jetty, quay, wharf or anchor buoy. It
        includes boats that are penned or in dry storage for part of the year.
      </Typography>
    ),
  };

  const commercialUseTooltipProps = {
    title: "Business or commercial use",
    testId: "commercialUseTooltip",
    message: (
      <Typography variant="body2">
        This includes boats that are hired out or that deliver goods or carry passengers for a fee.
      </Typography>
    ),
  };

  return {
    form,
    waterStoredTooltipProps,
    commercialUseTooltipProps,
    onSubmit,
  };
};

export default useLetsStart;
