import { SessionTimeoutDialog } from "../../shared/components/SessionTimeoutDialog";
import Form from "./Form";
import useMoreBoat from "./hooks/useMoreBoat";

export const MoreBoat: React.FC = () => {
  const props = useMoreBoat();
  return (
    <>
      <Form {...props} />
      <SessionTimeoutDialog />
    </>
  );
};

export default MoreBoat;
