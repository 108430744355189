import { useSetBackdrop } from "raci-react-library";
import { useEffect, useState } from "react";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { PaymentReferenceDataResponse } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";

export const useGetPaymentReferenceData = () => {
  const apiClient = useApiClient();
  const setBackdrop = useSetBackdrop();
  const [isError, setIsError] = useState<boolean>(false);
  const [paymentReferenceData, setPaymentReferenceData] = useState<PaymentReferenceDataResponse>();

  useEffect(() => {
    let isCancelled = false;
    const getPaymentReferenceData = async () => {
      setBackdrop(true);
      try {
        setIsError(false);
        const response = await apiClient.getpaymentreferencedata();
        setPaymentReferenceData(response.result);
      } catch (ex) {
        setIsError(true);
      } finally {
        setBackdrop(false);
      }
    };

    if (!isCancelled && !isError && !paymentReferenceData) {
      getPaymentReferenceData();
    }

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentReferenceData, isError]);

  return { paymentReferenceData, isError };
};

export default useGetPaymentReferenceData;
