import { styled, Typography } from "@mui/material";
import { Card } from "@racwa/react-components";
import { colors } from "@racwa/styles";

export const StyledCard = styled(Card)({
  margin: "0",
  padding: "24px 24px 0px 24px",
  textAlign: "left",
  border: `1px solid ${colors.dieselLight}`,
});

export const FaqHeading = styled(Typography)({
  fontWeight: 400,
  fontSize: "18px",
  margin: "0px",
});

export const FaqContent = styled(Typography)({
  fontWeight: 300,
  fontSize: "16px",
});
