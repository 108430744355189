import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Link, Typography } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { colors } from "@racwa/styles";
import { useGetSessionState } from "raci-react-library";
import { PurchaseRoutes } from "../../../../../../shared/routing/routes.config";
import { PaymentData as PaymentDataValues } from "./../../../../../Payment/types";

export interface SummaryItemProps extends React.ComponentProps<any> {
  title?: string;
  name: string;
  onClick?: () => void;
  gtmEvent?: string;
  paddingTop?: string | number;
}

export const SummaryItem: React.FC<SummaryItemProps> = ({ title, children, name, onClick, gtmEvent, paddingTop }) => {
  const { lockdownPreviousPage: disabledLink } = useGetSessionState<PaymentDataValues>(PurchaseRoutes.Payment);
  const onLinkClick = (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    gtmEvent && gtm(event(gtmEvent));
    if (!disabledLink) {
      onClick && onClick();
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        id={`${name}-summary-box-container`}
        data-testid={`${name}-summary-box-container`}
        paddingTop={paddingTop}
      >
        <Box width="100%" id={`${name}-summary-box-content`} data-testid={`${name}-summary-box-content`}>
          {title && (
            <Typography
              variant="h4"
              sx={{ margin: "10px 0 10px 0" }}
              id={`${name}-summary-title`}
              data-testid={`${name}-summary-title`}
            >
              {title}
            </Typography>
          )}
          {children}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingTop={title ? "31.98px" : "0px"}
          id={`${name}-summary-box-edit`}
          data-testid={`${name}-summary-box-edit`}
        >
          <Link
            id={`pencil-link-to-${name}-page`}
            data-testid={`pencil-link-to-${name}-page`}
            sx={
              disabledLink
                ? {
                    color: colors.racGray,
                    pointerEvents: "none",
                  }
                : {
                    fontSize: "1rem",
                    textDecoration: "underline",
                    marginLeft: "30px",
                    "& + a": {
                      marginLeft: "2px",
                    },
                    paddingRight: "5px",
                  }
            }
            variant="inherit"
            color="inherit"
            href="#"
            underline="none"
            onClick={onLinkClick}
          >
            <FontAwesomeIcon
              icon={faPencilAlt}
              id={`${name}-summary-pencil-icon`}
              data-testid={`${name}-summary-pencil-icon`}
            />
          </Link>{" "}
          <Link
            id={`html-link-to-${name}-page`}
            data-testid={`html-link-to-${name}-page`}
            sx={
              disabledLink
                ? {
                    color: colors.racGray,
                    pointerEvents: "none",
                  }
                : {
                    fontSize: "1rem",
                    textDecoration: "underline",
                    marginLeft: "20px",
                    "& + a": {
                      marginLeft: "2px",
                    },
                  }
            }
            variant="inherit"
            color="inherit"
            href="#"
            underline="none"
            onClick={onLinkClick}
          >
            Edit
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default SummaryItem;
