import { Button, Grid, Link, Typography } from "@mui/material";
import { BodyCopy, FinePrint, RacwaDetailedDialog } from "@racwa/react-components";
import { InsurancePhoneNumber } from "raci-react-library";
import React, { useEffect, useState } from "react";

interface ContentProps {
  policyNumber?: string;
}

const PaymentProcessingFailureContent: React.FC<ContentProps> = ({ policyNumber }) => {
  const policyNumberElementId = "try-again-dialog-span-policy-number";
  return (
    <Grid container spacing={2} paddingTop={"16px"}>
      <Grid item xs={12}>
        <Typography paragraph variant="body1">
          It looks like there's a problem with your card.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography paragraph variant="body1">
          But we've issued your policy and emailed your policy documents.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography paragraph variant="body1">
          You still need to pay, or your policy will be cancelled. You can try again or call us on{" "}
          <Link href={InsurancePhoneNumber.href} variant="inherit" noWrap>
            {InsurancePhoneNumber.display}
          </Link>{" "}
          .
        </Typography>
      </Grid>
      <Grid item xs={12} marginBottom={"32px"}>
        <FinePrint>
          Your policy number is{" "}
          <span id={policyNumberElementId} data-testid={policyNumberElementId}>
            {policyNumber}
          </span>
        </FinePrint>
      </Grid>
    </Grid>
  );
};

interface ActionProps {
  onDoneClick: () => void;
  onTryAgainClick: () => void;
}

const Actions: React.FC<ActionProps> = ({ onDoneClick, onTryAgainClick }) => {
  const btnDoneId = "btn-done";
  const btnTryAgainId = "btn-try-again";

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Button
          id={btnTryAgainId}
          data-testid={btnTryAgainId}
          name={btnTryAgainId}
          onClick={onTryAgainClick}
          color="primary"
          fullWidth
        >
          Try Again
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button id={btnDoneId} role="button" data-testid={btnDoneId} name={btnDoneId} onClick={onDoneClick} fullWidth>
          Done
        </Button>
      </Grid>
    </Grid>
  );
};

const PaymentSetupFailureContent = () => {
  return (
    <>
      <BodyCopy gutterBottom>
        <strong>Please check your details and try again, or try another payment method.</strong>
      </BodyCopy>
    </>
  );
};

export interface TryAgainDialogProps {
  open?: boolean;
  policyNumber?: string;
  onDoneClick?: () => void;
  onTryAgainClick?: () => void;
}

export const TryAgainDialog: React.FC<TryAgainDialogProps> = ({ open, policyNumber, onDoneClick, onTryAgainClick }) => {
  const [isOpen, setIsOpen] = useState(open || false);

  const onDoneClickWrapper = () => {
    setIsOpen(false);
    onDoneClick && onDoneClick();
  };
  const onTryAgainClickWrapper = () => {
    setIsOpen(false);
    onTryAgainClick && onTryAgainClick();
  };

  useEffect(() => {
    setIsOpen(open || false);
  }, [open]);

  const dialog =
    policyNumber === "" ? (
      <RacwaDetailedDialog
        open={isOpen}
        onClickClose={onTryAgainClickWrapper}
        title="Something went wrong"
        titleId="payment-setup-failure-dialog"
        maxWidth="xs"
        customChildren={[
          {
            dialogueContent: false,
            node: <PaymentSetupFailureContent />,
          },
          {
            dialogueContent: false,
            node: (
              <Button
                id="btn-close"
                data-testid="btn-close"
                name="btn-close"
                onClick={onTryAgainClickWrapper}
                color="primary"
              >
                Close
              </Button>
            ),
          },
        ]}
      />
    ) : (
      <RacwaDetailedDialog
        open={isOpen}
        onClickClose={onTryAgainClickWrapper}
        title="We couldn't process your payment"
        titleId="try-again-dialog"
        maxWidth="xs"
        customChildren={[
          {
            dialogueContent: false,
            node: <PaymentProcessingFailureContent policyNumber={policyNumber} />,
          },
          {
            dialogueContent: false,
            node: <Actions onDoneClick={onDoneClickWrapper} onTryAgainClick={onTryAgainClickWrapper} />,
          },
        ]}
      />
    );

  return isOpen ? dialog : null;
};

export default TryAgainDialog;
