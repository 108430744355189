import { Link } from "@mui/material";
import { RacwaCardNotification } from "@racwa/react-components";
import { InsurancePhoneNumberLink } from "raci-react-library";
import { StyledNotificationTypography } from "../../../styled";

const PersonalWatercraftCard = () => {
  return (
    <RacwaCardNotification id="personal-watercraft-notification" title="Sorry, we can't insure you" severity="error">
      <StyledNotificationTypography paragraph variant="body2" id="personal-watercraft-notification-body-1">
        We don't insure houseboats or personal watercrafts.
      </StyledNotificationTypography>
      <StyledNotificationTypography paragraph variant="body2" id="personal-watercraft-notification-body-3">
        Visit{" "}
        <Link
          href="https://findaninsurer.com.au"
          target="_blank"
          id="personal-watercraft-notification-link-2"
          rel="noreferrer noopener"
        >
          findaninsurer.com.au
        </Link>{" "}
        to find insurance for your boat.
      </StyledNotificationTypography>
      <StyledNotificationTypography variant="body2" id="personal-watercraft-notification-body-2">
        If you have any questions about this, please call us on{" "}
        <InsurancePhoneNumberLink id="personal-watercraft-notification-link-1" />.
      </StyledNotificationTypography>
    </RacwaCardNotification>
  );
};

export default PersonalWatercraftCard;
