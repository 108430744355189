import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { ClickAwayListener, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { MobileH3, RacwaTooltip } from "@racwa/react-components";
import { YesNoButtonGroup, trackCustomFormotivInput } from "raci-react-library";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import AgreedValue from "../../../../shared/components/AgreedValue";
import AgreedValueCard from "../../../../shared/components/AgreedValue/components/AgreedValueCard";
import { QuoteResponse } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { getYesNoLabel } from "../../../../shared/utils/helpers";
import { StyledButtonGrid, StyledTextGrid, StyledTooltipGrid, StyledTooltipIcon } from "../../styled";
import { TooltipData, YourQuoteState } from "../../types";
import { ExcessInput } from "./components/ExcessInput";

const floatationCoverName = "flotationCover";
const racingCoverName = "racingCover";

export interface PaymentAdjustmentProps {
  quote: QuoteResponse;
  showRacingCover: boolean;
  showLowerExcessMessage: boolean;
  tooltipData: TooltipData;
  onQuoteCustomisation?: (answers: YourQuoteState, forceSave?: boolean) => Promise<void>;
}

export const RacWaPaymentAdjustment: React.FC<PaymentAdjustmentProps> = ({
  quote,
  showRacingCover,
  showLowerExcessMessage,
  tooltipData,
  onQuoteCustomisation,
}) => {
  const { watch, getValues } = useFormContext<YourQuoteState>();
  const [agreedOpen, setAgreedOpen] = useState(false);
  const [excessOpen, setExcessOpen] = useState(false);
  const [racingOpen, setRacingOpen] = useState(false);
  const [flotationOpen, setFlotationOpen] = useState(false);

  const agreedValue = watch("agreedValue");
  const flotationCover = watch("flotationCover");
  const racingCover = watch("racingCover");

  const handleCloseTooltips = () => {
    if (agreedOpen) setAgreedOpen(false);
    if (excessOpen) setExcessOpen(false);
    if (racingOpen) setRacingOpen(false);
    if (flotationOpen) setFlotationOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={() => handleCloseTooltips()}>
      {/* SPK-2952: Added onClick to the Grid container due to a bug with ClickAwayListener not working within the grids. */}
      <Grid container onClick={() => handleCloseTooltips()}>
        <Grid item xs={12}>
          <MobileH3 paddingBottom={"16px"} paddingTop={"8px"}>
            Adjust the amount you pay
          </MobileH3>
          <hr />
        </Grid>
        <Grid container>
          <StyledTextGrid item xs={6}>
            <Typography variant="body1" id="label-excess-input">
              <b>Basic excess</b>
            </Typography>
          </StyledTextGrid>
          <StyledTooltipGrid item xs={2}>
            <RacwaTooltip
              {...tooltipData.excessTooltip}
              data-testid={tooltipData.excessTooltip.id}
              open={excessOpen}
              onClickClose={() => setExcessOpen(false)}
            >
              <IconButton
                size="small"
                aria-label="show tooltip"
                onClick={() => {
                  setExcessOpen(!excessOpen);
                  gtm(event("Tooltip: Basic excess"));
                }}
              >
                <StyledTooltipIcon icon={faQuestionCircle} />
              </IconButton>
            </RacwaTooltip>
          </StyledTooltipGrid>
          <Grid item xs={4}>
            <ExcessInput
              name="excess"
              id="excess-input"
              defaultValue={quote.cover?.excess.value ?? 0}
              excessOptions={quote.cover?.excess.options?.sort((a, b) => a - b) ?? []}
              showLowerExcessMessage={showLowerExcessMessage}
              onChange={async (value) => {
                onQuoteCustomisation && (await onQuoteCustomisation(value, true));
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
        <Grid container>
          <StyledTextGrid item xs={6}>
            <Typography variant="body1">
              <b>Agreed value</b>
            </Typography>
          </StyledTextGrid>
          <StyledTooltipGrid item xs={2}>
            <RacwaTooltip
              {...tooltipData.agreedValueTooltip}
              data-testid={tooltipData.agreedValueTooltip.id}
              open={agreedOpen}
              onClickClose={() => setAgreedOpen(false)}
            >
              <IconButton
                size="small"
                aria-label="show tooltip"
                onClick={() => {
                  setAgreedOpen(!agreedOpen);
                  gtm(event("Tooltip: Agreed value"));
                }}
              >
                <StyledTooltipIcon icon={faQuestionCircle} />
              </IconButton>
            </RacwaTooltip>
          </StyledTooltipGrid>
          <Grid item xs={4}>
            <AgreedValue
              name="agreedValue"
              id="agreed-value-input"
              onChangedValue={async (value) => {
                onQuoteCustomisation && (await onQuoteCustomisation(value));
                gtm(fieldTouched("Agreed value"));
              }}
            />
          </Grid>
          {Number(agreedValue) > 150000 && <AgreedValueCard />}
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
        <Grid container>
          <StyledTextGrid item xs={6}>
            <Typography variant="body1">
              <b>Add waterskiing and flotation device cover</b>
            </Typography>
          </StyledTextGrid>
          <StyledTooltipGrid item xs={2}>
            <RacwaTooltip
              {...tooltipData.flotationTooltip}
              data-testid={tooltipData.flotationTooltip.id}
              open={flotationOpen}
              onClickClose={() => setFlotationOpen(false)}
            >
              <IconButton
                size="small"
                aria-label="show tooltip"
                onClick={() => {
                  setFlotationOpen(!flotationOpen);
                  gtm(event("Tooltip: Add water-skiing and floatation device cover"));
                }}
              >
                <StyledTooltipIcon icon={faQuestionCircle} />
              </IconButton>
            </RacwaTooltip>
          </StyledTooltipGrid>
          <StyledButtonGrid item xs={4}>
            <YesNoButtonGroup
              name={floatationCoverName}
              id="flotation-cover"
              data-testid="flotation-cover"
              onBlur={() => gtm(fieldTouched("Add water-skiing and floatation device cover"))}
              onChange={async (_, value) => {
                trackCustomFormotivInput(getYesNoLabel(floatationCoverName, value), "button", value);
                const oldValue = flotationCover ?? false;
                const newValue = getValues().flotationCover;
                if (oldValue !== newValue) {
                  const request = {
                    ...getValues(),
                  };
                  onQuoteCustomisation && (await onQuoteCustomisation(request, true));
                }
              }}
            />
          </StyledButtonGrid>
        </Grid>
        {showRacingCover && (
          <>
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid container>
              <StyledTextGrid item xs={6}>
                <Typography variant="body1">
                  <b>Add racing cover</b>
                </Typography>
              </StyledTextGrid>
              <StyledTooltipGrid item xs={2}>
                <RacwaTooltip
                  {...tooltipData.racingCoverTooltip}
                  data-testid={tooltipData.racingCoverTooltip.id}
                  open={racingOpen}
                  onClickClose={() => setRacingOpen(false)}
                >
                  <IconButton
                    size="small"
                    aria-label="show tooltip"
                    onClick={() => {
                      setRacingOpen(!racingOpen);
                      gtm(event("Tooltip: Add racing cover"));
                    }}
                  >
                    <StyledTooltipIcon icon={faQuestionCircle} />
                  </IconButton>
                </RacwaTooltip>
              </StyledTooltipGrid>
              <StyledButtonGrid item xs={4}>
                <YesNoButtonGroup
                  name={racingCoverName}
                  id="racing-cover"
                  data-testid="racing-cover"
                  onBlur={() => gtm(fieldTouched("Add racing cover"))}
                  onChange={async (_, value) => {
                    trackCustomFormotivInput(getYesNoLabel(racingCoverName, value), "button", value);
                    const oldValue = racingCover ?? false;
                    const newValue = getValues().racingCover;
                    if (oldValue !== newValue) {
                      const request = {
                        ...getValues(),
                      };
                      onQuoteCustomisation && (await onQuoteCustomisation(request, true));
                    }
                  }}
                />
              </StyledButtonGrid>
            </Grid>
          </>
        )}
      </Grid>
    </ClickAwayListener>
  );
};

export default RacWaPaymentAdjustment;
