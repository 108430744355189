import { event, gtm } from "@racwa/analytics";
import { format } from "date-fns";
import { Claim as RRLClaim, useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useApiClient from "../../../../shared/hooks/useApiClient";
import {
  ApiException,
  BoatType,
  Claim,
  ClaimType,
  MoreBoatRequest,
  SecuritySystems,
} from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import useReferenceData from "../../../../shared/hooks/useReferenceData";
import {
  QUOTE_YOUR_QUOTE_PAGE_URL,
  QuoteRoutes,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
} from "../../../../shared/routing/routes.config";
import { toMultiChoice } from "../../../../shared/utils/helpers";
import { AboutYouState } from "../../../AboutYou/types";
import { BoatTypeState } from "../../../BoatType/types";
import { YourBoatState } from "../../../YourBoat/types";
import { FormProps, MoreBoatState } from "../../types";

export const useMoreBoat = (): FormProps => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const setIsOpen = useSetBackdrop();
  const { motorTypes, suburbs } = useReferenceData();
  const [moreBoatState, setMoreBoatState] = useSessionState<MoreBoatState>();
  const aboutYouState = useGetSessionState<AboutYouState>(QuoteRoutes.AboutYou);
  const boatTypeState = useGetSessionState<BoatTypeState>(QuoteRoutes.BoatType);
  const yourBoatState = useGetSessionState<YourBoatState>(QuoteRoutes.YourBoat);
  const [showGoldenRuleCard, setShowGoldenRuleCard] = useState(false);

  const form = useForm<MoreBoatState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: moreBoatState,
    shouldUnregister: true,
  });

  useEffect(() => {
    if (motorTypes.length < 1 || suburbs.length < 1) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
    return () => setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [motorTypes, suburbs]);

  const toDateString = (inDate: Date | null | undefined | string): Date =>
    //@ts-ignore - We require date to be in this particular format
    format((inDate && new Date(inDate)) || new Date(), "yyyy-MM-dd");

  const aggregateClaims = (claims: RRLClaim[]): Claim[] => {
    const claimTypeMap = new Map<ClaimType, number>();
    const claimYearMap = new Map<ClaimType, number[]>();

    claims.forEach((claim) => {
      const claimType = claim.externalCode as ClaimType;
      const claimYear = parseInt(claim.year);

      const currentCount = claimTypeMap.get(claimType) ?? 0;
      claimTypeMap.set(claimType, currentCount + 1);

      const currentYears = claimYearMap.get(claimType) ?? [];
      claimYearMap.set(claimType, [...currentYears, claimYear]);
    });

    const aggregatedClaims = Array.from(claimTypeMap.entries()).map(([claimType, numberOfClaims]) => ({
      claimedYears: claimYearMap.get(claimType) ?? [],
      claimType,
      numberOfClaims: numberOfClaims,
    }));

    return aggregatedClaims;
  };

  const onSubmit = async (moreBoatState: MoreBoatState) => {
    setMoreBoatState({ ...moreBoatState });

    if (!showGoldenRuleCard) {
      try {
        setIsOpen(true);

        const request: MoreBoatRequest = {
          boatType: boatTypeState.selectedBoatType as BoatType,
          hullConstruction: yourBoatState.hullConstruction,
          make: yourBoatState.make.externalCode,
          motorType: moreBoatState.motorType,
          builtYear: yourBoatState.year,
          otherMake: yourBoatState.otherMake ?? "",
          agreedValue: yourBoatState.agreedValue,
          isFinanced: yourBoatState.financed ?? false,
          financeCompanies: [yourBoatState.financier?.externalFinancierNumber ?? ""],
          isGaraged: moreBoatState.keptInGarage,
          isSecurityAlarmGps: moreBoatState.securitySystems.some(
            (x) => x.label === SecuritySystems.Alarm_or_GPS_tracker,
          ),
          isSecurityHitch: moreBoatState.securitySystems.some(
            (x) => x.label === SecuritySystems.Trailer_security_device,
          ),
          isSecurityNebo: moreBoatState.securitySystems.some((x) => x.label === SecuritySystems.Nebolink),
          suburb: moreBoatState.suburb.name,
          postcode: moreBoatState.suburb.postcode,
          dateOfBirth: toDateString(aboutYouState.dateOfBirth),
          skipperExperience: aboutYouState.skippersExperience,
          claimsHistory: aboutYouState.claimsHistory ? aggregateClaims(aboutYouState.claimsHistory) : [],
        };

        const response = await apiClient.moreboat(request);

        return new Promise<void>(() => {
          setMoreBoatState({ ...moreBoatState, isCompleted: true, quote: response.result });
          navigate(QUOTE_YOUR_QUOTE_PAGE_URL);
        });
      } catch (exception) {
        const errorResponse = exception as ApiException;
        if (errorResponse.status === 400) {
          setShowGoldenRuleCard(true);
          gtm(event("Sorry, we've hit a roadblock"));
        } else {
          navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
        }
      } finally {
        setIsOpen(false);
      }
    }
  };

  return {
    form,
    values: moreBoatState,
    data: { suburbs: suburbs, motorTypes: motorTypes, securitySystemOptions: toMultiChoice(SecuritySystems) },
    onSubmit,
    showGoldenRuleCard,
    setShowGoldenRuleCard,
  };
};

export default useMoreBoat;
