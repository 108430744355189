import { useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { YourRegistrationRequest } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import {
  PURCHASE_PAYMENT_PAGE_URL,
  QuoteRoutes,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
} from "../../../../shared/routing/routes.config";
import { MoreBoatState } from "../../../MoreBoat/types";
import { FormProps, YourRegistrationState } from "../../types";

export const useYourRegistration = (): FormProps => {
  const apiClient = useApiClient();
  const setIsOpen = useSetBackdrop();
  const [yourRegistrationState, setYourRegistrationState] = useSessionState<YourRegistrationState>();
  const [moreBoatState, setMoreBoatState] = useSessionState<MoreBoatState>({
    specificKey: QuoteRoutes.MoreBoat,
    skipPageTrackingRecalculation: true,
  });
  const navigate = useNavigate();

  const onSubmit = async (values: YourRegistrationState) => {
    setIsOpen(true);

    const yourRegistrationRequest: YourRegistrationRequest = {
      boatRegistration: values.knowBoatRegistration ? values.boatRego : "",
      trailerRegistration: values.knowTrailerRegistration ? values.trailerRego : "",
    };

    const response = await apiClient.yourregistration(yourRegistrationRequest);

    // Share the quote state
    setMoreBoatState({ ...moreBoatState, quote: response.result });

    try {
      return new Promise<void>(() => {
        setYourRegistrationState({ ...values, isCompleted: true });
        navigate(PURCHASE_PAYMENT_PAGE_URL);
      });
    } catch {
      navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
    } finally {
      setIsOpen(false);
    }
  };

  const form = useForm<YourRegistrationState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: yourRegistrationState,
    shouldUnregister: true,
  });

  return {
    form,
    onSubmit,
  };
};

export default useYourRegistration;
