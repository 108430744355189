import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClickAwayListener, FormLabel, Grid, IconButton, Typography } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { RacwaTooltip } from "@racwa/react-components";
import { useFormotiv } from "raci-react-library";
import { useState } from "react";
import { FormProvider } from "react-hook-form";
import { CallToActionButton } from "../../shared/components/CallToActionButton";
import FaqCard from "../../shared/components/FaqCard";
import { FORMOTIV_CONFIG } from "../../shared/constants";
import BoatFinance from "./components/BoatFinance";
import BoatMake from "./components/BoatMake";
import BoatValue from "./components/BoatValue";
import BoatYear from "./components/BoatYear";
import HullConstruction from "./components/HullConstruction";
import { FormProps } from "./types";

const CreateMessage: React.FC = () => {
  return (
    <>
      <Typography variant="body2">
        <b>This includes:</b>
      </Typography>
      <ul>
        <li>
          <Typography variant="body2">
            <b>The hull, motors, masts, spars, rigging, sails, trailer and accessories.</b>
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <b>
              Accessories: safety equipment, anchors, oars, paddles, detachable canopies, spare propeller, two-way
              radios, depth sounders, GPS devices, covers and equipment that's normally demountable. This excludes
              personal effects, fishing tackle and waterski equipment.
            </b>
          </Typography>
        </li>
      </ul>
    </>
  );
};

export const Form: React.FC<FormProps> = ({ form, onSubmit, data }) => {
  const { handleSubmit } = form;
  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);
  const [valueOpen, setValueOpen] = useState(false);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(formotivOnSubmitWrapper(onSubmit))} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2" id="header" data-testid="header">
              Your boat
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <BoatMake options={data.boatMakes} />
          </Grid>
          <Grid item xs={12}>
            <BoatYear id="boat-year" />
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: "16px" }}>
            <HullConstruction data={data.hullTypes} />
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={8}>
                <FormLabel id="value-of-boat-label">Value of boat</FormLabel>
              </Grid>
              <ClickAwayListener
                onClickAway={() => {
                  setValueOpen(false);
                }}
              >
                <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <RacwaTooltip
                    open={valueOpen}
                    data-testid="value-boat-tooltip"
                    id="value-boat-tooltip"
                    title="Value of boat"
                    message={<CreateMessage />}
                    onClickClose={() => setValueOpen(false)}
                  >
                    <IconButton
                      size="small"
                      aria-label="show tooltip"
                      onClick={() => {
                        setValueOpen(!valueOpen);
                        if (!valueOpen) gtm(event("Tooltip: Value of boat"));
                      }}
                    >
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </IconButton>
                  </RacwaTooltip>
                </Grid>
              </ClickAwayListener>
              <Grid item xs={12} sx={{ marginBottom: "0.5rem" }}>
                <Typography variant="body2" id="label-description" sx={{ fontSize: "1rem" }}>
                  What your boat, including accessories, would sell for in today's market.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <BoatValue />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <BoatFinance options={data.financiers} />
          </Grid>
          <Grid item xs={12}>
            <CallToActionButton type="submit" data-testid="submit" color="primary" variant="contained" fullWidth>
              Next
            </CallToActionButton>
            <FaqCard />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Form;
