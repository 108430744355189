import { useFormotiv, useGetPreviousPageSessionState } from "raci-react-library";
import { PropsWithChildren, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FORMOTIV_CONFIG } from "../../constants";
import {
  PURCHASE_CONFIRMATION_PAGE_URL,
  PURCHASE_PAYMENT_PAGE_URL,
  UTILITY_IMPORTANT_INFORMATION_URL,
} from "../routes.config";
import useRoutes from "../useRoutes";

export interface RouteGuardProps {
  disableChecks?: string[];
}

export const RouteGuard: React.FC<PropsWithChildren<RouteGuardProps>> = ({ disableChecks = [], children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { path: previousPageUrl, isCompleted: isPreviousPageCompleted } = useGetPreviousPageSessionState();
  const { importantInformationConfirmed, isCompleted, lockdownPreviousPage } = useRoutes();
  const { initialiseFormotiv } = useFormotiv(FORMOTIV_CONFIG);

  useEffect(() => {
    const currentLocation = location.pathname.toLowerCase();
    const bypass = disableChecks.filter((item) => item.toLowerCase() === currentLocation).length > 0;

    if (
      !bypass &&
      previousPageUrl &&
      !isPreviousPageCompleted &&
      importantInformationConfirmed &&
      !lockdownPreviousPage
    ) {
      navigate(previousPageUrl, { replace: true });
    }
    if (!bypass && !importantInformationConfirmed && currentLocation !== UTILITY_IMPORTANT_INFORMATION_URL) {
      navigate(UTILITY_IMPORTANT_INFORMATION_URL, { replace: true });
    }

    if (isCompleted && currentLocation !== PURCHASE_CONFIRMATION_PAGE_URL) {
      navigate(PURCHASE_CONFIRMATION_PAGE_URL, { replace: true });
    } else if (!isCompleted && lockdownPreviousPage && currentLocation !== PURCHASE_PAYMENT_PAGE_URL) {
      navigate(PURCHASE_PAYMENT_PAGE_URL, { replace: true });
    }
  }, [
    disableChecks,
    importantInformationConfirmed,
    isCompleted,
    isPreviousPageCompleted,
    location.pathname,
    lockdownPreviousPage,
    navigate,
    previousPageUrl,
  ]);

  useEffect(() => {
    initialiseFormotiv();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return <>{children}</>;
};

export default RouteGuard;
