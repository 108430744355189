import React from "react";
import { SessionTimeoutDialog } from "../../shared/components/SessionTimeoutDialog";
import { Form } from "./Form";
import { useYourDetails } from "./hooks/useYourDetails";

export const YourDetails: React.FC = () => {
  const props = useYourDetails();

  return (
    <>
      <Form {...props} />
      <SessionTimeoutDialog />
    </>
  );
};

export default YourDetails;
