import { Grid } from "@mui/material";
import { RacwaTextInput, RacwaTextInputProps } from "@racwa/react-components";
import { Controller, useFormContext } from "react-hook-form";

const MaxLength = 10;
const ValidationPattern = /^[a-zA-Z0-9]*$/;

export interface RegistrationProps extends RacwaTextInputProps {
  id: string;
  fieldName: string;
  label: string;
  errorMessage: string;
}

export const RegistrationInput: React.FC<RegistrationProps> = ({ id, fieldName, label, errorMessage, ...props }) => {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={{
        required: {
          value: true,
          message: errorMessage,
        },
        maxLength: {
          value: MaxLength,
          message: errorMessage,
        },
        pattern: {
          value: ValidationPattern,
          message: errorMessage,
        },
      }}
      render={({ field: { ref, onBlur, onChange: onControlChange, ...renderProps }, fieldState: { error } }) => (
        <Grid item xs={12}>
          <RacwaTextInput
            {...renderProps}
            {...props}
            onChange={(e) => {
              const val = e.target.value.toUpperCase();
              onControlChange(val);
            }}
            id={id}
            data-testid={id}
            label={label}
            placeholder="e.g. RAC123"
            onKeyPress={(e) => {
              const key = e.key;
              if (!key || !ValidationPattern.test(key)) {
                e.preventDefault();
              } else {
                props.onKeyPress?.(e);
              }
            }}
            onPaste={(e) => {
              const value = e.clipboardData.getData("Text");
              if (!ValidationPattern.test(value)) {
                e.preventDefault();
              } else {
                props.onPaste?.(e);
              }
            }}
            onBlur={(e: any) => {
              setValue(fieldName, e.target.value.trim(), { shouldValidate: true, shouldDirty: true });
              onBlur && onBlur();
              props.onBlur?.(e);
            }}
            error={!!error}
            helperText={error?.message || ""}
            inputProps={{
              maxLength: MaxLength,
            }}
            inputRef={ref}
          />
        </Grid>
      )}
    />
  );
};
