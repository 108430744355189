import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@mui/material";
import { Card } from "@racwa/react-components";
import { colors } from "@racwa/styles";

export const StyledTypography = styled(Typography)({
  fontWeight: 400,
  color: "#425563",
});

export const StyledTextGrid = styled(Grid)({
  padding: "1.45rem 1.45rem 1.45rem 0rem",
  textAlign: "left",
});

export const StyledButtonGrid = styled(Grid)({
  alignItems: "flex-end !Important",
  "& .MuiFormControl-root": { alignItems: "flex-end" },
});

export const StyledTooltipGrid = styled(Grid)({
  padding: "1.25rem",
  display: "flex",
  justifyContent: "flex-end",
});

export const StyledTooltipIcon = styled(FontAwesomeIcon)({
  color: colors.dieselDeep,
});

export const StyledCard = styled(Card)({
  margin: "0",
  padding: "1.25rem",
  textAlign: "left",
});
