import { FormControl, FormHelperText, OutlinedInput, OutlinedInputProps, SxProps, Theme } from "@mui/material";
import { THOUSAND_SEPARATOR_THRESHOLD } from "raci-react-library";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { NumberFormatValues, NumericFormat } from "react-number-format";
import { YourQuoteState } from "../../../views/YourQuote/types";

const defaultId = "agreed";
const defaultName = "agreed";
const placeHolder = "e.g. $5000";
const maxValue = 150000;
const minValue = 1;
const errorMessage = "Please enter a value";

export interface AgreedValueProps {
  id?: string;
  name?: string;
  sxProps?: SxProps<Theme> | undefined;
  defaultValue?: number;
  onChangedValue?: (value: YourQuoteState) => void;
  onBlur?: React.ComponentProps<typeof NumericFormat>["onBlur"];
}

export const NumericOutlinedInput: React.FC<OutlinedInputProps> = (props: OutlinedInputProps) => (
  <OutlinedInput {...props} inputProps={{ type: "text", inputMode: "numeric", "aria-label": "agreed-value" }} />
);

export const AgreedValue: React.FC<AgreedValueProps> = ({
  id = defaultId,
  name = defaultName,
  sxProps,
  defaultValue,
  onChangedValue,
  onBlur,
}) => {
  const { control, getValues, setValue, watch } = useFormContext();
  const [inputChanged, setInputChanged] = useState(false);
  const agreedValue = watch("agreedValue");

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      aria-label={id}
      name={name}
      rules={{
        required: { value: true, message: errorMessage },
        min: { value: minValue, message: errorMessage },
        validate: (value: string) => {
          return Number(value) >= minValue && Number(value) <= maxValue ? true : "";
        },
      }}
      render={({
        field: { ref, onChange: onComponentChange, onBlur: onComponentBlur, ...inputProps },
        fieldState: { error },
      }) => (
        <FormControl error={!!error} fullWidth sx={sxProps}>
          <NumericFormat
            {...inputProps}
            placeholder={placeHolder}
            thousandSeparator={(agreedValue as number) >= THOUSAND_SEPARATOR_THRESHOLD ? true : undefined}
            defaultValue={defaultValue}
            prefix={"$"}
            id={id}
            allowNegative={false}
            allowLeadingZeros={false}
            decimalScale={0}
            data-testid={id}
            color="primary"
            customInput={NumericOutlinedInput}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              if (e.key === "Enter") {
                e.currentTarget.blur();
              }
            }}
            onValueChange={(newValue: NumberFormatValues) => {
              onComponentChange(newValue?.value);
              newValue && setValue(name, newValue.value);
              setInputChanged(true);
            }}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              onComponentBlur && onComponentBlur();
              if (inputChanged) {
                const request = {
                  ...getValues(),
                };
                onChangedValue && onChangedValue(request as YourQuoteState);
                setInputChanged(false);
              }
              onBlur?.(e);
            }}
            inputRef={ref}
          />
          {error?.message && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default AgreedValue;
