import { Grid, Typography } from "@mui/material";
import { ClaimsHistory, DateOfBirthInput, useFormotiv } from "raci-react-library";
import { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { CallToActionButton } from "../../shared/components/CallToActionButton";
import FaqCard from "../../shared/components/FaqCard";
import { FORMOTIV_CONFIG } from "../../shared/constants";
import SkippersExperience from "./components/SkippersExperience";
import { FormProps } from "./types";

const dateOfBirthName = "dateOfBirth";

export const Form: React.FC<FormProps> = ({ form, onSubmit, data }) => {
  const { handleSubmit, watch, setValue, getValues } = form;
  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);
  const dateOfBirth = watch(dateOfBirthName);
  const [changedDOB, setChangedDOB] = useState(false);

  // useWatch will not pick up the default value of a field unless we
  // set the value to itself after initialising the field.
  useEffect(() => {
    setValue("numberOfClaims", getValues("numberOfClaims"));
    setValue(dateOfBirthName, getValues(dateOfBirthName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(formotivOnSubmitWrapper(onSubmit))} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2" id="header" data-testid="header">
              About you
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: "-8px" }}>
            <DateOfBirthInput id="about-you-dob" label="Date of birth" onChange={() => setChangedDOB(true)} />
          </Grid>
          <Grid item xs={12}>
            <SkippersExperience dateOfBirth={dateOfBirth} calculate={changedDOB} />
          </Grid>
          <Grid item xs={12}>
            <ClaimsHistory {...data} label="Number of boat claims you've made in the last three years" asset="boat" />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "-8px" }}>
            <CallToActionButton type="submit" data-testid="submit" color="primary" variant="contained" fullWidth>
              Next
            </CallToActionButton>
            <FaqCard />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Form;
