import { MenuItem } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { RacwaSelect } from "@racwa/react-components";
import { trackCustomFormotivInput } from "raci-react-library";
import { Controller, useFormContext } from "react-hook-form";
import { ReferenceDataOption } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";

const hullConstructionLabel = "Hull construction";
const hullConstructionName = "hullConstruction";

export interface HullConstructionProps {
  value?: HullConstructionValue;
  data?: ReferenceDataOption[];
}

export interface HullConstructionValue {
  hullType: string;
}

export const HullConstruction: React.FC<HullConstructionProps> = ({ data, value = "" }) => {
  const { setValue, control } = useFormContext();

  return (
    <Controller
      control={control}
      name={hullConstructionName}
      aria-label={hullConstructionLabel}
      defaultValue={value ?? ""}
      rules={{
        required: { value: true, message: "Please select one" },
      }}
      render={({ field: { ref, value, onBlur, onChange: onComponentChange, ...props }, fieldState: { error } }) => (
        <RacwaSelect
          value={value}
          id="your-boat-hull-select"
          data-testid="your-boat-hull-select"
          label={hullConstructionLabel}
          fullWidth
          displayEmpty
          onChange={(e) => {
            const hullType = e.target.value as string;
            trackCustomFormotivInput(hullConstructionName, "select", hullType);
            onComponentChange && onComponentChange(hullType);
            value && setValue(props.name, hullType);
          }}
          onBlur={() => onBlur()}
          onOpen={() => gtm(fieldTouched(hullConstructionLabel))}
          error={!!error}
          helperText={error?.message || ""}
          inputRef={ref}
        >
          <MenuItem key="disabled" disabled selected hidden value="">
            Please select one
          </MenuItem>
          {data?.map((value, index) => {
            return (
              <MenuItem key={index} value={value.description}>
                {value.description}
              </MenuItem>
            );
          })}
        </RacwaSelect>
      )}
    />
  );
};

export default HullConstruction;
