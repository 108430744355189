import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import { theme } from "@racwa/react-components";
import { colors } from "@racwa/styles";

export const StyledContainer = styled(Container)({
  textAlign: "center",
  "& .MuiDivider-root": {
    margin: "16px 0 16px 0",
    color: colors.dieselLight,
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    marginBottom: "68px",
  },
});

export const StyledGridContainer = styled(Grid)({
  textAlign: "center",
  "& .MuiDivider-root": {
    margin: "16px 0 16px 0",
    color: colors.dieselLight,
    width: "100%",
  },
});
