import { useSetRecoilState } from "recoil";
import { atomTimeoutTime } from "../../atoms";
import { TIMEOUT_30_MINUTES_MS } from "../../constants";
import useApiClient from "../useApiClient";

export const useResetTimeout = (): (() => void) => {
  const client = useApiClient();
  const setTimeoutTime = useSetRecoilState(atomTimeoutTime);

  const resetTimeout = async () => {
    // Extend the local timeout value to prevent the timeout dialog from
    // immediately showing again while we wait for the response from the BFF
    setTimeoutTime(new Date().getTime() + TIMEOUT_30_MINUTES_MS);

    try {
      const response = await client.resetsessionexpiry();
      setTimeoutTime(new Date().getTime() + response.result * 1000);
    } catch (ex) {
      // Do nothing; the next expiry check will realign the atom
    }
  };

  return resetTimeout;
};
