import { Grid } from "@mui/material";
import { useGetSessionState } from "raci-react-library";
import { useNavigate } from "react-router-dom";
import useReferenceData from "../../../../../../../shared/hooks/useReferenceData";
import {
  QUOTE_BOAT_TYPE_PAGE_URL,
  QUOTE_YOUR_BOAT_PAGE_URL,
  QuotePageNames,
  QuoteRoutes,
} from "../../../../../../../shared/routing/routes.config";
import { options } from "../../../../../../BoatType/hooks/useBoatType";
import { BoatTypeState } from "../../../../../../BoatType/types";
import { YourBoatState } from "../../../../../../YourBoat/types";
import { StyledTypography } from "../../../styles";
import SummaryItem from "../../SummaryItem";

export const YourBoatPolicySummaryItem: React.FC = () => {
  const { hullTypes } = useReferenceData();
  const yourBoatState = useGetSessionState<YourBoatState>(QuoteRoutes.YourBoat);
  const boatTypeState = useGetSessionState<BoatTypeState>(QuoteRoutes.BoatType);

  const selectedBoatType = options.find((boatType) => boatType.value === boatTypeState.selectedBoatType)?.value;

  const getHullType = (externalCode: string) => {
    return hullTypes.find((x) => x.externalCode === externalCode)?.description ?? externalCode;
  };

  const navigate = useNavigate();
  return (
    <Grid container>
      <Grid item xs={12}>
        <SummaryItem
          title={QuotePageNames.YourBoat}
          name={QuoteRoutes.YourBoat}
          onClick={() => navigate(QUOTE_YOUR_BOAT_PAGE_URL)}
        >
          <StyledTypography
            variant="body2"
            id={`${QuoteRoutes.YourBoat}-make-hull-year-text`}
            data-testid={`${QuoteRoutes.YourBoat}-make-hull-year-text`}
          >
            {yourBoatState.make?.externalCode === "Other" ? yourBoatState.otherMake : yourBoatState.make?.description},{" "}
            {getHullType(yourBoatState.hullConstruction)}, {yourBoatState.year}
          </StyledTypography>
        </SummaryItem>
        <SummaryItem name={QuoteRoutes.BoatType} onClick={() => navigate(QUOTE_BOAT_TYPE_PAGE_URL)} paddingTop={"16px"}>
          <StyledTypography
            variant="body2"
            id={`${QuoteRoutes.BoatType}-text`}
            data-testid={`${QuoteRoutes.BoatType}-text`}
          >
            {selectedBoatType}
          </StyledTypography>
        </SummaryItem>
      </Grid>
    </Grid>
  );
};
