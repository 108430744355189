import { useSessionState } from "raci-react-library";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SessionTimeoutDialog } from "../../shared/components/SessionTimeoutDialog";
import { QUOTE_LETS_START_PAGE_URL } from "../../shared/routing/routes.config";
import Form from "./Form";
import { ImportantInformationState } from "./types";

export const ImportantInformation: React.FC = () => {
  const navigate = useNavigate();
  const [state, setState] = useSessionState<ImportantInformationState>();

  const onSubmit = (value: ImportantInformationState) => {
    return new Promise<void>(() => {
      setState({ ...value, isCompleted: true });
      navigate(QUOTE_LETS_START_PAGE_URL);
    });
  };

  useEffect(() => {
    if (state.importantInformationConfirmed) {
      navigate(QUOTE_LETS_START_PAGE_URL, { replace: true });
    }
  }, [navigate, state.importantInformationConfirmed]);

  return (
    <>
      <Form values={state} onSubmit={onSubmit} />
      <SessionTimeoutDialog />
    </>
  );
};

export default ImportantInformation;
