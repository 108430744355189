import React from "react";
import { SessionTimeoutDialog } from "../../shared/components/SessionTimeoutDialog";
import { Form } from "./Form";
import { useYourRegistration } from "./hooks/useYourRegistration";

export const YourRegistration: React.FC = () => {
  const props = useYourRegistration();
  return (
    <>
      <Form {...props} />
      <SessionTimeoutDialog />
    </>
  );
};

export default YourRegistration;
