import { useEffect } from "react";
import { CXONE_RAC_BUSINESS_NUMBER, CXONE_RAC_POC } from "../../constants";

declare global {
  interface Window {
    icPatronChat: any;
  }
}

const useLoadWebChatScript = (enabled: boolean | undefined) => {
  const cxOneServerHost = process.env.REACT_APP_CXONE_SERVER_HOST;
  const cxOneChatClientJs = process.env.REACT_APP_CXONE_CHAT_CLIENT_JS;

  useEffect(() => {
    if (!document.querySelector(`script[src="${cxOneChatClientJs}"]`) && cxOneChatClientJs && enabled) {
      const scriptElement: HTMLScriptElement = document.createElement("script");
      scriptElement.async = true;
      scriptElement.src = cxOneChatClientJs;
      scriptElement.type = "text/javascript";

      scriptElement.onload = () => {
        window.icPatronChat.init({
          serverHost: cxOneServerHost,
          bus_no: CXONE_RAC_BUSINESS_NUMBER,
          poc: CXONE_RAC_POC,
        });
      };

      document.body.appendChild(scriptElement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);
};

export default useLoadWebChatScript;
