import { Grid, Typography } from "@mui/material";
import { endOfDay, startOfDay } from "date-fns";
import { KeyboardDateInput, PremiumChangeDialog, trackCustomFormotivInput, useFormotiv } from "raci-react-library";
import { FormProvider } from "react-hook-form";
import { CallToActionButton } from "../../shared/components/CallToActionButton";
import FaqCard from "../../shared/components/FaqCard";
import { FORMOTIV_CONFIG } from "../../shared/constants";
import { FormProps } from "./types";

const startDateName = "policyStartDate";

export const Form: React.FC<FormProps> = ({
  form,
  quote,
  paymentFrequency,
  onSubmit,
  redirectToNextPage,
  showDialog,
  setShowDialog,
}) => {
  const { handleSubmit } = form;
  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);

  const minDate = quote.startDate.min == null ? new Date() : new Date(quote.startDate.min);
  const maxDate = quote.startDate.max == null ? new Date() : new Date(quote.startDate.max);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(formotivOnSubmitWrapper(onSubmit))} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2" id="header" data-testid="header">
              Great, let's set a start date
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "16px" }}>
            <KeyboardDateInput
              label="Choose a start date for your policy"
              readOnlyTextField={true}
              id={startDateName}
              data-testid={startDateName}
              name={startDateName}
              errorMessage="Please select a date"
              minDate={startOfDay(minDate)}
              maxDate={endOfDay(maxDate)}
              clickAnywhereToShowCalendar={true}
              onDateChange={(date) => trackCustomFormotivInput(startDateName, "date", date)}
            />
          </Grid>
          <Grid item xs={12}>
            <CallToActionButton type="submit" data-testid="submit" color="primary" variant="contained" fullWidth>
              Next
            </CallToActionButton>
            <FaqCard />
          </Grid>
        </Grid>
        <PremiumChangeDialog
          title="We've updated your quote"
          showDialog={showDialog || false}
          setShowDialog={() => setShowDialog(!showDialog)}
          paymentFrequency={paymentFrequency}
          onButtonClick={redirectToNextPage}
          onClose={redirectToNextPage}
          premiumDetails={quote.cover?.premiumDetails!}
        />
      </form>
    </FormProvider>
  );
};

export default Form;
