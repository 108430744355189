import { SessionTimeoutDialog } from "../../shared/components/SessionTimeoutDialog";
import Form from "./Form";
import { useStartDate } from "./hooks/useStartDate";

export const StartDate: React.FC = () => {
  const props = useStartDate();

  return (
    <>
      <Form {...props} />
      <SessionTimeoutDialog />
    </>
  );
};

export default StartDate;
