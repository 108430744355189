import { Grid } from "@mui/material";
import { currencyFormatter, useGetSessionState } from "raci-react-library";
import { useNavigate } from "react-router-dom";
import { BoatType } from "../../../../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { QUOTE_YOUR_QUOTE_PAGE_URL, QuoteRoutes } from "../../../../../../../shared/routing/routes.config";
import { BoatTypeState } from "../../../../../../BoatType/types";
import { YourQuoteState } from "../../../../../../YourQuote/types";
import { StyledDivider, StyledTypography } from "../../../styles";
import SummaryItem from "../../SummaryItem";

export const YourCoverPolicySummaryItem: React.FC = () => {
  const navigate = useNavigate();
  const yourCoverName = "your-cover";
  const yourQuoteState = useGetSessionState<YourQuoteState>(QuoteRoutes.YourQuote);
  const boatTypeState = useGetSessionState<BoatTypeState>(QuoteRoutes.BoatType);

  return (
    <Grid container style={{ paddingTop: "6px" }}>
      <Grid item xs={12}>
        <SummaryItem title={"Your cover"} name={yourCoverName} onClick={() => navigate(QUOTE_YOUR_QUOTE_PAGE_URL)}>
          <StyledTypography
            variant="body2"
            id={`${yourCoverName}-agreed-value-text`}
            data-testid={`${yourCoverName}-agreed-value-text`}
          >
            Agreed value{" "}
            {currencyFormatter(Number(yourQuoteState.agreedValue), { stripZeroCents: true, withDecimals: false })}
          </StyledTypography>
          <StyledTypography
            variant="body2"
            id={`${yourCoverName}-excess-text`}
            data-testid={`${yourCoverName}-excess-text`}
          >
            Basic excess{" "}
            {currencyFormatter(Number(yourQuoteState.excess), { stripZeroCents: true, withDecimals: false })}
          </StyledTypography>

          <StyledTypography
            variant="body2"
            id={`${yourCoverName}-waterskiing-cover-text`}
            data-testid={`${yourCoverName}-waterskiing-cover-text`}
          >
            {yourQuoteState.flotationCover ? "Includes" : "No"} waterskiing and flotation device cover
          </StyledTypography>

          {boatTypeState.selectedBoatType === BoatType.SailBoat ? (
            <StyledTypography
              variant="body2"
              id={`${yourCoverName}-racing-cover-text`}
              data-testid={`${yourCoverName}-racing-cover-text`}
            >
              {yourQuoteState.racingCover ? "Includes" : "No"} racing cover
            </StyledTypography>
          ) : null}
        </SummaryItem>
        <StyledDivider />
      </Grid>
    </Grid>
  );
};
