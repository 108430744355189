import { SessionTimeoutDialog } from "../../shared/components/SessionTimeoutDialog";
import Form from "./Form";
import { usePayment } from "./hooks/usePayment";

export const Payment: React.FC = () => {
  const { ...props } = usePayment();

  return (
    <>
      <Form {...props} />
      <SessionTimeoutDialog />
    </>
  );
};

export default Payment;
