import { useSetBackdrop } from "raci-react-library";
import { PropsWithChildren, useEffect } from "react";
import useCreateSession from "../../hooks/useCreateSession";
import useSessionExpiry from "../../hooks/useSessionExpiry";

const RacwaSession: React.FC<PropsWithChildren> = ({ children }) => {
  useSessionExpiry();
  const status = useCreateSession();
  const setIsOpen = useSetBackdrop();

  useEffect(() => {
    if (status === "loading") {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
    return () => setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return <>{children}</>;
};

export default RacwaSession;
