import { format } from "date-fns";
import { useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { premiumDetailsState } from "../../../../shared/atoms";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { QuoteResponse, StartDateRequest } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import {
  PURCHASE_YOUR_DETAILS_PAGE_URL,
  QuoteRoutes,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
} from "../../../../shared/routing/routes.config";
import { MoreBoatState } from "../../../MoreBoat/types";
import { YourQuoteState } from "../../../YourQuote/types";
import { FormProps, StartDateState } from "../../types";

const parseDate = (inDate: string | undefined | null | Date): Date | undefined => {
  if (!inDate) {
    return undefined;
  }

  if (Object.prototype.toString.call(inDate) === "[object Date]") {
    return inDate as Date;
  }

  return new Date(Date.parse(inDate as string));
};

export const useStartDate = (): FormProps => {
  const apiClient = useApiClient();
  const premiumDetails = useRecoilValue(premiumDetailsState);
  const navigate = useNavigate();
  const [state, setState] = useSessionState<StartDateState>();
  const [showDialog, setShowDialog] = useState(false);
  const [moreBoatState, setMoreBoatState] = useSessionState<MoreBoatState>({
    specificKey: QuoteRoutes.MoreBoat,
    skipPageTrackingRecalculation: true,
  });
  const { paymentFrequency } = useGetSessionState<YourQuoteState>(QuoteRoutes.YourQuote);
  const setIsOpen = useSetBackdrop();

  const handlePremiumChanges = (result: QuoteResponse) => {
    const hasChanged = result.cover.premiumDetails.annual.total - premiumDetails.annual.total;
    if (hasChanged) {
      setShowDialog(true);
    } else {
      redirectToNextPage();
    }
  };

  const onSubmit = async (value: StartDateState) => {
    setIsOpen(true);
    const dateForRequest = format(value.policyStartDate, "yyyy-MM-dd") as unknown as Date;

    const startDateRequest: StartDateRequest = {
      startDate: dateForRequest,
    };

    try {
      const response = await apiClient.startdate(startDateRequest);

      setMoreBoatState({ ...moreBoatState, quote: response.result });

      return new Promise<void>(() => {
        setState({ ...value, isCompleted: true });
        handlePremiumChanges(response.result);
      });
    } catch {
      navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
    } finally {
      setIsOpen(false);
    }
  };

  const redirectToNextPage = () => {
    navigate(PURCHASE_YOUR_DETAILS_PAGE_URL);
  };

  const form = useForm<StartDateState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      ...state,
      policyStartDate: (state.policyStartDate && parseDate(state.policyStartDate)) ?? new Date(),
    },
  });

  return {
    form: form,
    quote: moreBoatState.quote,
    paymentFrequency,
    onSubmit,
    redirectToNextPage,
    showDialog,
    setShowDialog,
  };
};
