import { Address, SessionState } from "raci-react-library";
import { UseFormReturn } from "react-hook-form";
import { Gender, Title } from "../../shared/hooks/useApiClient/ClientProxy.generated";

export interface Data {
  list: string[];
}

export interface YourDetailsState extends SessionState {
  title?: Title;
  gender?: Gender;
  firstName?: string;
  lastName?: string;
  contactNumber?: string;
  email?: string;
  mailingAddress?: Address;
}

export interface FormProps {
  form: UseFormReturn<YourDetailsState>;
  onSubmit: (answers: YourDetailsState) => Promise<void>;
  findAddressErrorType?: ErrorTypes;
  addressResults: Address[];
  onAddressInputChange: (text: string) => void;
  onAddressSelection: (address: Address | null) => void;
  resetError: () => void;
}

export interface UseRiskAddressReturn {
  addressResults: Address[];
  onAddressInputChange: (text: string) => void;
  onAddressSelection: (address: Address | null) => void;
}

export enum ErrorTypes {
  None,
  NoMatch,
  MatchAttemptsExhausted,
}
