import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

let App: any;

/*
 ** WARNING: KNOW WHAT YOU'RE DOING BEFORE MAKING CHANGES TO
 ** TO THE BELOW IF STATEMENT
 ** UPON A BUILD, REACT SCRIPT WILL REMOVE CODE IN
 ** THE OUTPUTTED JAVASCRIPT FILE DEPENDING ON THE TARGET
 ** ENVIRONMENT
 ** THIS MEANS THAT FOR LOCAL/DEV/SIT BUILDS WILL INCLUDE
 ** AND SHIP CODE (YOU CAN SEE AND SEARCH FOR IN OUTPUTTED
 ** JAVASCRIPT ARTIFACTS) FOR MICROSOFT AUTHENTICATION LIBRARY
 */
if (
  process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
  process.env.REACT_APP_ENVIRONMENT === "DEV" ||
  process.env.REACT_APP_ENVIRONMENT === "SIT" ||
  process.env.REACT_APP_ENVIRONMENT === "UAT"
) {
  const { RacwaInternalAuth, FeatureToggleProvider, ShieldEnvironmentProvider } = require("raci-react-library-npe");
  const AppInternal = require("./App").default;
  const featureToggles = JSON.parse(process.env.REACT_APP_FEATURE_TOGGLES ?? "[]");
  const environments = JSON.parse(process.env.REACT_APP_SHIELD_ENVIRONMENTS ?? "[]");
  const NpeApp = () => (
    <FeatureToggleProvider featureToggles={featureToggles}>
      <ShieldEnvironmentProvider environments={environments}>
        <AppInternal />
      </ShieldEnvironmentProvider>
    </FeatureToggleProvider>
  );

  if (process.env.REACT_APP_ENVIRONMENT !== "UAT") {
    App = () => (
      <RacwaInternalAuth
        clientId={process.env.REACT_APP_OAUTH_CLIENT_ID}
        authority={process.env.REACT_APP_OAUTH_AUTHORITY}
        redirectUri={process.env.REACT_APP_ENVIRONMENT === "LOCAL" ? window.location.origin : process.env.PUBLIC_URL}
      >
        <NpeApp />
      </RacwaInternalAuth>
    );
  } else {
    App = () => <NpeApp />;
  }
} else {
  App = require("./App").default;
}

const container = document.getElementById("root");

if (container !== null) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
