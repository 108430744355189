import { MenuItem } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { RacwaSelect } from "@racwa/react-components";
import { trackCustomFormotivInput } from "raci-react-library";
import { Controller, useFormContext } from "react-hook-form";
import { ReferenceDataOption } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";

const motorTypeLabel = "Type of motor";
const motorTypeName = "motorType";

export interface MotorTypeProps {
  value?: MotorTypeValue;
  data?: ReferenceDataOption[];
}

export interface MotorTypeValue {
  motorType: string;
}

export const MotorTypeInput: React.FC<MotorTypeProps> = ({ data, value = "" }) => {
  const { setValue, control } = useFormContext();

  return (
    <Controller
      control={control}
      name={motorTypeName}
      aria-label="Motor type"
      defaultValue={value ?? ""}
      rules={{
        required: { value: true, message: "Please select one" },
      }}
      render={({ field: { ref, value, onBlur, onChange: onComponentChange, ...props }, fieldState: { error } }) => (
        <RacwaSelect
          value={value}
          id="more-boat-motor-type-select"
          label={motorTypeLabel}
          fullWidth
          displayEmpty
          onChange={(e) => {
            const motorType = e.target.value as string;
            trackCustomFormotivInput(motorTypeName, "select", motorType);
            onComponentChange && onComponentChange(motorType);
            value && setValue(props.name, motorType);
            gtm(event(motorType));
          }}
          onOpen={() => gtm(fieldTouched(motorTypeLabel))}
          error={!!error}
          helperText={error?.message || ""}
          inputRef={ref}
        >
          <MenuItem key="disabled" disabled selected hidden value="">
            Please select one
          </MenuItem>
          {data?.map((value, index) => {
            return (
              <MenuItem key={index} value={value.description}>
                {value.description}
              </MenuItem>
            );
          })}
        </RacwaSelect>
      )}
    />
  );
};

export default MotorTypeInput;
