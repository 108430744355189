import { MultiChoiceOption } from "raci-react-library";

export const toArray = (value: any): string[] => Object.keys(value).map((key) => value[key]);
export const toMultiChoice = (value: any): MultiChoiceOption[] =>
  Object.keys(value).map((key, index) => ({
    key: key,
    label: value[key],
  }));
export const getYesNoLabel = (label: string, value: string | number | boolean | null | undefined): string => {
  return `${label} - ${value === "true" ? "Yes" : "No"}`;
};
