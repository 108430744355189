import { Grid } from "@mui/material";
import { useGetSessionState } from "raci-react-library";
import { useNavigate } from "react-router-dom";
import { SecuritySystems } from "../../../../../../../shared/hooks/useApiClient/ClientProxy.generated";
import {
  QUOTE_LETS_START_PAGE_URL,
  QUOTE_MORE_BOAT_PAGE_URL,
  QUOTE_YOUR_BOAT_PAGE_URL,
  QuoteRoutes,
} from "../../../../../../../shared/routing/routes.config";
import { MoreBoatState } from "../../../../../../MoreBoat/types";
import { YourBoatState } from "../../../../../../YourBoat/types";
import { StyledDivider, StyledTypography } from "../../../styles";
import SummaryItem from "../../SummaryItem";

export const MoreBoatPolicySummaryItem: React.FC = () => {
  const yourBoatState = useGetSessionState<YourBoatState>(QuoteRoutes.YourBoat);
  const moreBoatState = useGetSessionState<MoreBoatState>(QuoteRoutes.MoreBoat);
  const navigate = useNavigate();
  const order = [
    SecuritySystems.Trailer_security_device.valueOf(),
    SecuritySystems.Alarm_or_GPS_tracker.valueOf(),
    SecuritySystems.Nebolink.valueOf(),
  ];

  const getMotorType = (externalCode: string) => {
    switch (externalCode) {
      case "Inboard":
        return "Inboard motor";
      case "Outboard":
        return "Outboard motor";
      default:
        return "No motor";
    }
  };

  const getFinancier = (financierName?: string) => {
    return financierName === "Other" ? "Other financier" : financierName;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SummaryItem
          title="More about your boat"
          name={QuoteRoutes.MoreBoat}
          onClick={() => navigate(QUOTE_LETS_START_PAGE_URL)}
        >
          <StyledTypography
            variant="body2"
            id={`${QuoteRoutes.MoreBoat}-storage-text`}
            data-testid={`${QuoteRoutes.MoreBoat}-storage-text`}
          >
            Not usually stored in the water
          </StyledTypography>
          <StyledTypography
            variant="body2"
            id={`${QuoteRoutes.MoreBoat}-commercial-use-text`}
            data-testid={`${QuoteRoutes.MoreBoat}-commercial-use-text`}
          >
            No business or commercial use
          </StyledTypography>
          <StyledTypography
            variant="body2"
            id={`${QuoteRoutes.MoreBoat}-personal-watercraft-text`}
            data-testid={`${QuoteRoutes.MoreBoat}-personal-watercraft-text`}
          >
            Not houseboat or personal watercraft
          </StyledTypography>
          <StyledTypography
            variant="body2"
            id={`${QuoteRoutes.MoreBoat}-value-text`}
            data-testid={`${QuoteRoutes.MoreBoat}-value-text`}
          >
            Boat valued under $150,000
          </StyledTypography>
        </SummaryItem>
      </Grid>
      <Grid item xs={12}>
        <SummaryItem name={QuoteRoutes.MoreBoat} onClick={() => navigate(QUOTE_YOUR_BOAT_PAGE_URL)}>
          <StyledTypography
            variant="body2"
            id={`${QuoteRoutes.MoreBoat}-finance-text`}
            data-testid={`${QuoteRoutes.MoreBoat}-finance-text`}
          >
            {yourBoatState.financed
              ? "Financed with " + (yourBoatState.financier && getFinancier(yourBoatState.financier.financierName))
              : "No boat finance"}
          </StyledTypography>
        </SummaryItem>
      </Grid>
      <Grid item xs={12}>
        <SummaryItem name={QuoteRoutes.MoreBoat} onClick={() => navigate(QUOTE_MORE_BOAT_PAGE_URL)}>
          <StyledTypography
            variant="body2"
            id={`${QuoteRoutes.MoreBoat}-suburb-text`}
            data-testid={`${QuoteRoutes.MoreBoat}-suburb-text`}
          >
            Kept in {moreBoatState.suburb.name} {moreBoatState.suburb.postcode}
          </StyledTypography>
          <StyledTypography
            variant="body2"
            id={`${QuoteRoutes.MoreBoat}-garage-text`}
            data-testid={`${QuoteRoutes.MoreBoat}-garage-text`}
          >
            {!moreBoatState.keptInGarage ? "Not kept" : "Kept"} in a garage
          </StyledTypography>
          <StyledTypography
            variant="body2"
            id={`${QuoteRoutes.MoreBoat}-motor-type-text`}
            data-testid={`${QuoteRoutes.MoreBoat}-motor-type-text`}
          >
            {getMotorType(moreBoatState.motorType)}
          </StyledTypography>
          {moreBoatState.securitySystems
            .sort((a, b) => order.indexOf(a.label) - order.indexOf(b.label))
            .map((system) => (
              <StyledTypography
                key={system.key}
                variant="body2"
                id={`${QuoteRoutes.MoreBoat}-${system.key.replace(/_/gi, "-").toLowerCase()}-text`}
                data-testid={`${QuoteRoutes.MoreBoat}-${system.key.replace(/_/gi, "-").toLowerCase()}-text`}
              >
                {system.label}
              </StyledTypography>
            ))}
        </SummaryItem>
        <StyledDivider />
      </Grid>
    </Grid>
  );
};
