import { useShieldEnvironment } from "raci-react-library-npe";
import useLoadReferenceData from "../../shared/hooks/useLoadReferenceData";

export const LoadReferenceDataNpe = () => {
  const { selected: shieldEnvironment } = useShieldEnvironment();
  useLoadReferenceData(shieldEnvironment);

  return null;
};

export default LoadReferenceDataNpe;
