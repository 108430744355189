import { SessionTimeoutDialog } from "../../shared/components/SessionTimeoutDialog";
import Form from "./Form";
import { useYourBoat } from "./hooks/useYourBoat";

export const YourBoat: React.FC = () => {
  const props = useYourBoat();

  return (
    <>
      <Form {...props} />
      <SessionTimeoutDialog />
    </>
  );
};

export default YourBoat;
