import RetrieveQuoteForm from "./Form";
import { useRetrieveQuote } from "./hooks/useRetrieveQuote";

export const RetrieveQuote: React.FC = () => {
  const props = useRetrieveQuote();

  return <RetrieveQuoteForm {...props} />;
};

export default RetrieveQuote;
