import { FormControl, FormHelperText, FormLabel, Typography } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { RacwaCheckboxGroup, RacwaCheckboxListItem } from "@racwa/react-components";
import { BasicTooltip, MultiChoiceOption } from "raci-react-library";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { SecuritySystems } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { StyledBox, StyledGrid } from "./styles";

export const testId = "security-systems-multi-choice-input";
export const errorMessage = "Please select all options that apply";
const securitySystemsName = "securitySystems";

export interface SecuritySystemInputProps {
  options?: MultiChoiceOption[];
}

const securitySystemTooltipProps = {
  title: "Security systems",
  id: "security-systems-tooltip",
  testId: "security-systems-tooltip",

  message: (
    <>
      <Typography variant="body2">
        <b>Trailer security device: </b>A tool that prevents theft of your boat or trailer, e.g. wheel or hitch locks.
      </Typography>
      <br />
      <Typography variant="body2">
        <b>Alarm or GPS tracker: </b>GPS is technology that provides the exact location of your boat.
      </Typography>
      <br />
      <Typography variant="body2">
        <b>nebolink: </b>A boat monitoring and voyage logging system.
      </Typography>
    </>
  ),
};

export const SecuritySystemInput: React.FC<SecuritySystemInputProps> = ({ options }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={securitySystemsName}
      defaultValue={[]}
      rules={{
        validate: (value) => {
          return value?.length > 0 || errorMessage;
        },
      }}
      render={({ field: { onChange: onControlChange, value, ref }, fieldState: { error } }) => {
        const onSelectionChanged = (option: MultiChoiceOption, checked: boolean) => {
          const typedValued = value as MultiChoiceOption[];
          if (checked && !typedValued.some((item) => item.key === option.key)) {
            if (option.label === SecuritySystems.No_security) {
              onControlChange([option]);
            } else {
              onControlChange([...typedValued, option].filter((item) => item.label !== SecuritySystems.No_security));
            }
            gtm(event(option.label));
          } else if (!checked && typedValued.some((item) => item.key === option.key)) {
            onControlChange(typedValued.filter((value) => value.key !== option.key));
          }
        };

        return (
          <FormControl error={!!error} fullWidth id={testId} data-testid={testId}>
            <StyledBox>
              <FormLabel id={`${testId}-label`} data-testid={`${testId}-label`}>
                Security systems on your boat
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1rem" }}
                  id={`${testId}-sublabel`}
                  data-testid={`${testId}-sublabel`}
                >
                  You can choose more than one option
                </Typography>
              </FormLabel>
              <div style={{ alignSelf: "flex-start" }}>
                <BasicTooltip {...securitySystemTooltipProps} />
              </div>
            </StyledBox>
            <StyledGrid container item xs={12} sm={12} direction="column">
              <RacwaCheckboxGroup
                border
                error={!!error?.message}
                onBlur={() => gtm(fieldTouched("Security systems on your boat"))}
              >
                {options?.map((option) => (
                  <RacwaCheckboxListItem
                    key={option.key}
                    name={option.key}
                    id={`${testId}-${option.key}`}
                    data-testid={`${testId}-${option.key}`}
                    value={option.key}
                    checked={value.some((item: MultiChoiceOption) => item.key === option.key) ?? false}
                    label={option.label}
                    sublabel={option.sublabel}
                    onChange={(ev) => onSelectionChanged(option, ev.currentTarget.checked)}
                  />
                ))}
              </RacwaCheckboxGroup>
            </StyledGrid>
            {error?.message && <FormHelperText sx={{ fontSize: "0.75rem" }}>{error.message}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};

export default SecuritySystemInput;
