import { event, gtm } from "@racwa/analytics";
import { useSetBackdrop } from "raci-react-library";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { ApiException } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import useReferenceData from "../../../../shared/hooks/useReferenceData";
import { QUOTE_YOUR_QUOTE_PAGE_URL } from "../../../../shared/routing/routes.config";
import { FormProps, RetrieveQuoteState } from "../../types";
import useUpdateRetrieveQuoteState from "../useUpdateRetrieveQuoteState";

const maxRetryAttemps = 5;

export const useRetrieveQuote = (): FormProps => {
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const setIsOpen = useSetBackdrop();
  const { suburbs } = useReferenceData();
  const [attempts, setAttempts] = useState(0);
  const attemptsExhausted = useMemo(() => attempts > maxRetryAttemps, [attempts]);
  const [quoteNotFound, setQuoteNotFound] = useState(false);

  const form = useForm<RetrieveQuoteState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    shouldUnregister: true,
  });

  const updateRetrieveQuoteState = useUpdateRetrieveQuoteState();

  const redirectToYourQuotePage = () => {
    navigate(QUOTE_YOUR_QUOTE_PAGE_URL);
  };

  const onSubmit = async (data: RetrieveQuoteState) => {
    try {
      setIsOpen(true);
      setQuoteNotFound(false);
      const response = await apiClient.retrievequote(data.quoteNumber, data.suburb.name, data.suburb.postcode);
      updateRetrieveQuoteState(response.result);
      redirectToYourQuotePage();
    } catch (exception) {
      const errorResponse = exception as ApiException;
      if (errorResponse.status === 429) {
        setAttempts(99);
        gtm(event("Sorry, we can't retrieve your quote right now"));
      } else {
        setAttempts((a) => a + 1);
        setQuoteNotFound(true);
        gtm(event("Your quote number and suburb don't match"));
      }
    } finally {
      setIsOpen(false);
    }
  };

  return {
    form,
    attemptsExhausted,
    onSubmit,
    suburbs,
    attempts,
    quoteNotFound,
    setQuoteNotFound,
  };
};
