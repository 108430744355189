import { RacwaCardNotification } from "@racwa/react-components";
import { InsurancePhoneNumberLink } from "raci-react-library";
import { StyledNotificationTypography } from "../../../styled";

const GoldenRuleCard = () => {
  return (
    <RacwaCardNotification id="golden-rule-notification" title="Sorry, we've hit a roadblock" severity="error">
      <StyledNotificationTypography variant="body2" id="golden-rule-notification-body-1">
        We'd like to talk to you about your options.
      </StyledNotificationTypography>
      <StyledNotificationTypography variant="body2" id="golden-rule-notification-body-2">
        Please call us on <InsurancePhoneNumberLink id="golden-rule-notification-link-1" />.
      </StyledNotificationTypography>
    </RacwaCardNotification>
  );
};

export default GoldenRuleCard;
