import { FormControl, FormHelperText, FormLabel, OutlinedInput, OutlinedInputProps } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { PatternFormat } from "react-number-format";

const defaultId = "boatYear";
const defaultName = "year";
const maxDate = new Date().getFullYear();
const validationMessage = "Please enter a valid year";
const boatYearLabel = "Year";
export interface BoatYearProps {
  id?: string;
  name?: string;
}

export const NumericOutlinedInput: React.FC<OutlinedInputProps> = (props: OutlinedInputProps) => (
  <OutlinedInput {...props} inputProps={{ type: "text", inputMode: "numeric", "aria-label": "boat-year-value" }} />
);

export const BoatYear: React.FC<BoatYearProps> = ({ id = defaultId, name = defaultName }) => {
  const { control } = useFormContext();
  const [, setState] = useState<number | undefined>();
  return (
    <Controller
      control={control}
      aria-label={id}
      name={name}
      rules={{
        required: { value: true, message: validationMessage },
        validate: (value: string) => {
          return Number(value) <= maxDate ? true : validationMessage;
        },
      }}
      render={({ field: { ref, onChange: onComponentChange, onBlur, ...inputProps }, fieldState: { error } }) => (
        <FormControl error={!!error} fullWidth>
          <FormLabel>Year</FormLabel>
          <PatternFormat
            {...inputProps}
            placeholder="YYYY"
            format="####"
            id={id}
            data-testid={id}
            label={boatYearLabel}
            color="primary"
            customInput={NumericOutlinedInput}
            onValueChange={(newValue) => {
              onComponentChange(newValue?.value);
              setState(Number.parseInt(newValue?.value));
            }}
            onBlur={() => {
              onBlur?.();
              gtm(fieldTouched(boatYearLabel));
            }}
            inputRef={ref}
          />
          {error?.message && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default BoatYear;
