import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { ApiException, Gender, Member, Title } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import {
  PURCHASE_YOUR_REGISTRATION_PAGE_URL,
  QuoteRoutes,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
} from "../../../../shared/routing/routes.config";
import { AboutYouState } from "../../../AboutYou/types";
import { ErrorTypes, FormProps, YourDetailsState } from "../../types";
import useFindAddress from "../useFindAddress";

const createRequest = (yourDetailsState: YourDetailsState, dateOfBirth: Date): Member => {
  if (yourDetailsState.title === undefined) throw Error("A title must be specified");
  if (yourDetailsState.gender === undefined) throw Error("A gender must be specified");

  const request = {
    title: yourDetailsState.title,
    gender: yourDetailsState.gender,
    firstName: yourDetailsState.firstName ?? "",
    surname: yourDetailsState.lastName ?? "",
    dateOfBirth: (dateOfBirth && new Date(format(parseISO(dateOfBirth.toString()), "yyyy-MM-dd"))) ?? null,
    email: yourDetailsState.email ?? "",
    contactNumber: yourDetailsState.contactNumber?.replace(/\s/g, "") ?? "",
    mailingAddress: yourDetailsState.mailingAddress?.moniker ?? "",
  };

  return request;
};
const deriveGenderFromTitle = (title: string | null | undefined) => {
  if (title === Title.Mr) {
    return Gender.Male;
  } else if (title === Title.Miss || title === Title.Mrs || title === Title.Ms) {
    return Gender.Female;
  }
};

export const useYourDetails = (): FormProps => {
  const apiClient = useApiClient();
  const [findAddressErrorType, setFindAddressErrorType] = useState(ErrorTypes.None);
  const [yourDetailsState, setYourDetailsState] = useSessionState<YourDetailsState>();
  const { onAddressInputChange, onAddressSelection, addressResults } = useFindAddress(yourDetailsState.mailingAddress);
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  const { dateOfBirth } = useGetSessionState<AboutYouState>(QuoteRoutes.AboutYou);

  const redirectToSystemUnavailable = (status: number) => {
    navigate(`${UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL}/${status}`);
  };

  const onSubmit = async (values: YourDetailsState) => {
    setBackdrop(true);
    values = { ...values, gender: values.gender || deriveGenderFromTitle(values.title) };
    const request = createRequest(values, dateOfBirth);
    setYourDetailsState({
      ...request,
      title: values.title || undefined,
      gender: values.gender,
      lastName: values.lastName || undefined,
      mailingAddress: values.mailingAddress,
      isCompleted: true,
    });
    try {
      await apiClient.updatemember(request);
      navigate(PURCHASE_YOUR_REGISTRATION_PAGE_URL);
    } catch (exception) {
      const errorResponse = exception as ApiException;
      redirectToSystemUnavailable(errorResponse.status);
    } finally {
      setBackdrop(false);
    }
  };

  const form = useForm<YourDetailsState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: yourDetailsState,
    shouldUnregister: true,
  });

  const resetError = () => {
    if (findAddressErrorType !== ErrorTypes.MatchAttemptsExhausted) {
      setFindAddressErrorType(ErrorTypes.None);
    }
  };

  return {
    form,
    findAddressErrorType,
    onSubmit,
    onAddressInputChange,
    onAddressSelection,
    addressResults,
    resetError,
  };
};

export default useYourDetails;
