import { useEffect } from "react";
import { SessionTimeoutDialog } from "../../shared/components/SessionTimeoutDialog";
import { UTILITY_SESSION_TIMEOUT_PAGE_URL } from "../../shared/routing/routes.config";
import Form from "./Form";
import useConfirmation from "./hooks";

const PAGE_TIMEOUT_INTERVAL_MILLISECONDS = 15 * 60 * 1000; //15 minutes

export const Confirmation: React.FC = () => {
  const { ...props } = useConfirmation();

  useEffect(() => {
    const handle = setTimeout(() => {
      window.location.href = UTILITY_SESSION_TIMEOUT_PAGE_URL;
    }, PAGE_TIMEOUT_INTERVAL_MILLISECONDS);
    return () => {
      clearTimeout(handle);
    };
  });

  return (
    <>
      <Form {...props} />
      <SessionTimeoutDialog />
    </>
  );
};
export default Confirmation;
