let PreloadReferenceData: () => null = () => null;

if (
  process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
  process.env.REACT_APP_ENVIRONMENT === "DEV" ||
  process.env.REACT_APP_ENVIRONMENT === "SIT" ||
  process.env.REACT_APP_ENVIRONMENT === "UAT"
) {
  PreloadReferenceData = require("../../../__NPE__/LoadReferenceData").default;
} else {
  PreloadReferenceData = require("../LoadReferenceData").default;
}

export default PreloadReferenceData;
