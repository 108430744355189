import { LinkProps } from "@mui/material";
import {
  FooterProps,
  RacwaBreadcrumbDetails,
  RacwaResponsiveHeaderProps,
  RacwaStepperTemplateProps,
} from "@racwa/react-components";
import { EMPTY_URL, InsurancePhoneNumber, PhoneButton } from "raci-react-library";
import { useLocation } from "react-router-dom";
import { UtilityPageNames, utilityRoutes } from "../../routing/routes.config";

type CommonPageProps = Pick<
  RacwaStepperTemplateProps,
  | "breadcrumbs"
  | "responsiveHeaderProps"
  | "headerEndAction"
  | "notificationBannerContent"
  | "showNotificationBanner"
  | "footerProps"
>;

const getLinkProps = (link?: string): Partial<LinkProps> => ({
  href: link ?? EMPTY_URL,
});

export const usePage = (): CommonPageProps => {
  const location = useLocation();

  const currentUtilityRoute = utilityRoutes.find(
    (route) => route.path.toLowerCase() === location.pathname?.toLowerCase(),
  );

  const breadcrumbs: RacwaBreadcrumbDetails = {
    links: [
      { name: "Home & Life", href: process.env.REACT_APP_HOME_AND_LIFE_URL ?? "#", key: "HomeAndLife" },
      { name: "Boat insurance", href: process.env.REACT_APP_BOAT_INSURANCE_URL ?? "#", key: "BoatInsurance" },
    ],
    currentPage: {
      name:
        currentUtilityRoute?.name === UtilityPageNames.ImportantInformation
          ? UtilityPageNames.ImportantInformation
          : "Quote",
    },
  };

  const responsiveHeaderProps: RacwaResponsiveHeaderProps = {
    NavBreadcrumbsProps: {
      homeLink: process.env.REACT_APP_RAC_HOME_PAGE ?? EMPTY_URL,
    },
  };

  const footerProps: FooterProps = {
    variant: "sidebar",
    privacyLinkProps: getLinkProps(process.env.REACT_APP_RAC_ABOUT_PRIVACY_PAGE),
    securityLinkProps: getLinkProps(process.env.REACT_APP_RAC_ABOUT_SECURITY_PAGE),
    disclaimerLinkProps: getLinkProps(process.env.REACT_APP_RAC_ABOUT_DISCLAIMER_PAGE),
    accessibilityLinkProps: getLinkProps(process.env.REACT_APP_RAC_ABOUT_ACCESSIBILITY_PAGE),
  };

  let notificationBannerContent;

  const phoneButton = <PhoneButton phone={InsurancePhoneNumber} id={"header-phone-button"} />;
  let headerEndAction = phoneButton;

  /*
   ** WARNING:
   ** KNOW WHAT YOU'RE DOING BEFORE MAKING CHANGES TO TO THE BELOW IF STATEMENT
   ** UPON A BUILD, REACT SCRIPT WILL REMOVE CODE IN THE OUTPUTTED JAVASCRIPT FILE DEPENDING ON THE TARGET ENVIRONMENT
   ** THIS MEANS THAT FOR LOCAL/DEV/SIT BUILDS WILL INCLUDE AND SHIP CODE
   ** (YOU CAN SEE AND SEARCH FOR IN OUTPUTTED JAVASCRIPT ARTIFACTS)
   */
  if (
    process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
    process.env.REACT_APP_ENVIRONMENT === "DEV" ||
    process.env.REACT_APP_ENVIRONMENT === "SIT"
  ) {
    const { HeaderButtons, TestBanner } = require("raci-react-library-npe");

    const { EnvironmentHealthStatus } = require("../../../__NPE__/EnvironmentHealthStatus");

    notificationBannerContent = <TestBanner />;

    headerEndAction = <HeaderButtons phoneButton={phoneButton} npeButtons={[<EnvironmentHealthStatus />]} />;
  }

  return {
    breadcrumbs,
    responsiveHeaderProps,
    headerEndAction,
    notificationBannerContent,
    showNotificationBanner: !!notificationBannerContent,
    footerProps,
  };
};

export default usePage;
