import { Address, useDebounce } from "raci-react-library";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { ApiException } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL } from "../../../../shared/routing/routes.config";
import { UseRiskAddressReturn } from "../../types";

export const useFindAddress = (initialAddress?: Address): UseRiskAddressReturn => {
  const apiClient = useApiClient();
  const [searchTerm, setSearchTerm] = useState<string>(initialAddress?.displayAddress ?? "");
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [address, setAddress] = useState<Address | null>(initialAddress || null);
  const navigate = useNavigate();
  const minimumLengthToSearch = 10;

  const redirectToSystemUnavailable = (status: number) => {
    navigate(`${UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL}/${status}`);
  };

  const onAddressSelection = (selectedAddress: Address | null) => {
    setSearchTerm(selectedAddress?.displayAddress || "");
    setAddress(selectedAddress || null);
    setAddresses([]);
  };

  const onAddressInputChange = (value: string) => {
    if (!value) {
      setAddress(null);
      setAddresses([]);
    }

    if (addresses.some((a) => a.displayAddress?.toLocaleLowerCase().includes(value.toLocaleLowerCase())) === false) {
      setAddresses([]);
    }

    setSearchTerm(value);
  };

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    let isCancelled = false;

    if (debouncedSearchTerm) {
      if (debouncedSearchTerm.length >= minimumLengthToSearch && debouncedSearchTerm !== address?.displayAddress) {
        const fetchAddresses = async () => {
          try {
            if (!isCancelled) {
              const response = await apiClient.getaddresses(debouncedSearchTerm);
              setAddresses(response.result);
            }
          } catch (exception) {
            const errorResponse = exception as ApiException;
            if (errorResponse.status >= 500) {
              redirectToSystemUnavailable(errorResponse.status);
            }
            setAddresses([]);
          }
        };

        fetchAddresses();
      }
    } else {
      setAddress(null);
      setAddresses([]);
    }

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return {
    onAddressInputChange,
    onAddressSelection,
    addressResults: addresses,
  };
};

export default useFindAddress;
