import { atom } from "recoil";
import { SESSION_KEY_ATOM_TIMEOUT, TIMEOUT_30_MINUTES_MS } from "../constants";
import {
  Financier,
  PaymentReferenceDataResponse,
  PremiumDetails,
  ReferenceDataOption,
  Suburb,
} from "../hooks/useApiClient/ClientProxy.generated";

export interface FormotivQuoteNumber {
  hasBeenPushed: boolean;
}

export const formotivQuoteNumber = atom<FormotivQuoteNumber>({
  key: "formotiveQuoteNumber",
  default: { hasBeenPushed: false },
});

export const claimTypesAtom = atom<ReferenceDataOption[]>({
  key: "ClaimTypeList",
  default: [],
});

export const motorTypesAtom = atom<ReferenceDataOption[]>({
  key: "MotorTypeList",
  default: [],
});

export const hullTypesAtom = atom<ReferenceDataOption[]>({
  key: "HullTypeList",
  default: [],
});

export const suburbsAtom = atom<Suburb[]>({
  key: "SuburbList",
  default: [],
});

export const financiersAtom = atom<Financier[]>({
  key: "FinancierList",
  default: [],
});

export const boatMakesAtom = atom<ReferenceDataOption[]>({
  key: "BoatMakesList",
  default: [],
});

export const securityTokenAtom = atom<PaymentReferenceDataResponse>({
  key: "westpacPublishableApiKey",
  default: {},
});

export const atomTimeoutTime = atom<number>({
  key: SESSION_KEY_ATOM_TIMEOUT,
  default: new Date().getTime() + TIMEOUT_30_MINUTES_MS,
});

export const premiumDetailsState = atom<PremiumDetails>({
  key: "premiumDetailsState",
  default: {
    annual: { total: 0, gst: 0, basic: 0, governmentCharges: 0 },
    monthly: { total: 0, gst: 0, basic: 0, governmentCharges: 0, instalment: 0 },
  },
});

export const isAgentFetchingAtom = atom<boolean>({
  key: "isAgentFetching",
  default: false,
});
