import { Phone, PhoneRounded } from "@mui/icons-material";
import { Button, Grid, Link, Typography } from "@mui/material";
import { gtm, phoneCall } from "@racwa/analytics";
import { FinePrint } from "@racwa/react-components";
import { InsurancePhoneNumber } from "raci-react-library";
import { StyledGridContainer } from "../../styles";

export interface UnsuccessfulConfirmationProps {
  policyNumber?: string;
  inline?: boolean;
}

const UnsuccessfulConfirmation: React.FC<UnsuccessfulConfirmationProps> = ({ policyNumber, inline = false }) => {
  return (
    <StyledGridContainer container justifyContent="center" alignItems="center">
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <PhoneRounded sx={{ fontSize: 80 }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" id="header" data-testid="header">
            Please call us to pay
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            We couldn't process your payment.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            But we've issued your policy and emailed your policy documents. You still need to pay.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            If you forget to pay by the due date, your policy will be cancelled.
          </Typography>
        </Grid>
        <Grid item xs={12} data-testid="policyNumberLabel">
          <FinePrint>Your policy number is {policyNumber}</FinePrint>
        </Grid>
      </Grid>
      <Grid container>
        <div
          style={{
            display: "flex",
            flexDirection: inline ? "row" : "column",
            width: "100%",
            marginTop: "5rem",
          }}
        >
          <Grid item xs={12} margin={"0.5rem"}>
            <Link
              id={"call-link"}
              data-testid={"call-link"}
              variant="inherit"
              color="inherit"
              href={InsurancePhoneNumber.href}
              onClick={() => gtm(phoneCall(InsurancePhoneNumber.displayWithoutSpaces))}
              target="_blank"
              rel="noreferrer noopener"
              underline="none"
            >
              <Button
                id="call-link-button"
                type="button"
                role="button"
                color="primary"
                variant="contained"
                fullWidth={true}
                startIcon={<Phone />}
              >
                {InsurancePhoneNumber.display}
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12} margin={"0.5rem"}>
            <Link
              id={"home-page-link"}
              data-testid={"home-page-link"}
              variant="inherit"
              color="inherit"
              href={process.env.REACT_APP_RAC_HOME_PAGE}
              target="_blank"
              rel="noreferrer noopener"
              underline="none"
            >
              <Button
                id="home-page-link-button"
                type="button"
                role="button"
                color="secondary"
                variant="contained"
                fullWidth={true}
              >
                Go to RAC homepage
              </Button>
            </Link>
          </Grid>
        </div>
      </Grid>
    </StyledGridContainer>
  );
};

export default UnsuccessfulConfirmation;
