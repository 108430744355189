import { Grid } from "@mui/material";
import { useGetSessionState } from "raci-react-library";
import { useNavigate } from "react-router-dom";
import { PURCHASE_START_DATE_PAGE_URL, PurchaseRoutes } from "../../../../../../../shared/routing/routes.config";
import { StartDateState } from "../../../../../../StartDate/types";
import { StyledDivider, StyledTypography } from "../../../styles";
import SummaryItem from "../../SummaryItem";

export const StartDatePolicySummaryItem: React.FC = () => {
  const navigate = useNavigate();
  const startDateState = useGetSessionState<StartDateState>(PurchaseRoutes.StartDate);

  const formattedStartDate =
    startDateState.policyStartDate && new Date(startDateState.policyStartDate).toLocaleDateString("en-AU");

  return (
    <Grid container>
      <Grid item xs={12}>
        <SummaryItem
          title="Policy start date"
          name={PurchaseRoutes.StartDate}
          onClick={() => navigate(PURCHASE_START_DATE_PAGE_URL)}
        >
          <StyledTypography
            variant="body2"
            id={`${PurchaseRoutes.StartDate}-text`}
            data-testid={`${PurchaseRoutes.StartDate}-text`}
          >
            Policy start date {formattedStartDate}
          </StyledTypography>
        </SummaryItem>
        <StyledDivider />
      </Grid>
    </Grid>
  );
};
