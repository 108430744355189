import { useSessionState } from "raci-react-library";
import { AboutYouState } from "../../../views/AboutYou/types";
import { BoatTypeState } from "../../../views/BoatType/types";
import { ImportantInformationState } from "../../../views/ImportantInformation/types";
import { LetsStartState } from "../../../views/LetsStart/types";
import { MoreBoatState } from "../../../views/MoreBoat/types";
import { StartDateState } from "../../../views/StartDate/types";
import { YourBoatState } from "../../../views/YourBoat/types";
import { YourDetailsState } from "../../../views/YourDetails/types";
import { YourQuoteState } from "../../../views/YourQuote/types";
import { YourRegistrationState } from "../../../views/YourRegistration/types";
import { PurchaseRoutes, QuoteRoutes, UtilityRoutes } from "../../routing/routes.config";

export type UPDATE_SESSION_STATE_ACTIONTYPE =
  | { type: "important-information"; payload: Partial<ImportantInformationState> }
  | { type: "lets-start"; payload: Partial<LetsStartState> }
  | { type: "about-you"; payload: Partial<AboutYouState> }
  | { type: "boat-type"; payload: Partial<BoatTypeState> }
  | { type: "your-boat"; payload: Partial<YourBoatState> }
  | { type: "more-about-your-boat"; payload: Partial<MoreBoatState> }
  | { type: "your-quote"; payload: Partial<YourQuoteState> }
  | { type: "start-date"; payload: Partial<StartDateState> }
  | { type: "your-details"; payload: Partial<YourDetailsState> }
  | { type: "your-registration"; payload: Partial<YourRegistrationState> };

export const useUpdateSessionState = () => {
  const [importantInformationState, setImportantInformationState] = useSessionState<ImportantInformationState>({
    specificKey: UtilityRoutes.ImportantInformation,
    skipPageTrackingRecalculation: true,
  });

  const [letsStartState, setLetsStartState] = useSessionState<LetsStartState>({
    specificKey: QuoteRoutes.LetsStart,
    skipPageTrackingRecalculation: true,
  });

  const [aboutYouState, setAboutYouState] = useSessionState<AboutYouState>({
    specificKey: QuoteRoutes.AboutYou,
    skipPageTrackingRecalculation: true,
  });

  const [boatTypeState, setBoatTypeState] = useSessionState<BoatTypeState>({
    specificKey: QuoteRoutes.BoatType,
    skipPageTrackingRecalculation: true,
  });

  const [yourBoatState, setYourBoatState] = useSessionState<YourBoatState>({
    specificKey: QuoteRoutes.YourBoat,
    skipPageTrackingRecalculation: true,
  });

  const [moreBoatState, setMoreBoatState] = useSessionState<MoreBoatState>({
    specificKey: QuoteRoutes.MoreBoat,
    skipPageTrackingRecalculation: true,
  });

  const [yourQuoteState, setYourQuoteState] = useSessionState<YourQuoteState>({
    specificKey: QuoteRoutes.YourQuote,
    skipPageTrackingRecalculation: true,
  });

  const [startDateState, setStartDateState] = useSessionState<StartDateState>({
    specificKey: PurchaseRoutes.StartDate,
    skipPageTrackingRecalculation: true,
  });

  const [yourDetailsState, setYourDetailsState] = useSessionState<YourDetailsState>({
    specificKey: PurchaseRoutes.YourDetails,
    skipPageTrackingRecalculation: true,
  });

  const [yourRegistrationState, setYourRegistrationState] = useSessionState<YourRegistrationState>({
    specificKey: PurchaseRoutes.YourRegistration,
    skipPageTrackingRecalculation: true,
  });

  return (action: UPDATE_SESSION_STATE_ACTIONTYPE) => {
    switch (action.type) {
      case "important-information":
        setImportantInformationState({ ...importantInformationState, ...action.payload });
        break;
      case "lets-start":
        setLetsStartState({ ...letsStartState, ...action.payload });
        break;
      case "about-you":
        setAboutYouState({ ...aboutYouState, ...action.payload });
        break;
      case "boat-type":
        setBoatTypeState({ ...boatTypeState, ...action.payload });
        break;
      case "your-boat":
        setYourBoatState({ ...yourBoatState, ...action.payload });
        break;
      case "more-about-your-boat":
        setMoreBoatState({ ...moreBoatState, ...action.payload });
        break;
      case "your-quote":
        setYourQuoteState({ ...yourQuoteState, ...action.payload });
        break;
      case "start-date":
        setStartDateState({ ...startDateState, ...action.payload });
        break;
      case "your-details":
        setYourDetailsState({ ...yourDetailsState, ...action.payload });
        break;
      case "your-registration":
        setYourRegistrationState({ ...yourRegistrationState, ...action.payload });
        break;
      default:
        break;
    }
  };
};
