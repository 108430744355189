import { Box, Grid, styled } from "@mui/material";

export const StyledGrid = styled(Grid)({
  "& > *:not(:first-child)": {
    borderTop: "0",
  },
});

export const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  "& > label": {
    marginBottom: 8,
  },
});
