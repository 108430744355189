import { Grid, Link, Typography } from "@mui/material";
import { RacwaCardNotification } from "@racwa/react-components";
import { InsurancePhoneNumberLink, QuoteNumberInput, QuotePrefix, SuburbInput, useFormotiv } from "raci-react-library";
import React, { PropsWithChildren, memo, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { CallToActionButton } from "../../shared/components/CallToActionButton";
import FaqCard from "../../shared/components/FaqCard";
import { FORMOTIV_CONFIG } from "../../shared/constants";
import { UTILITY_IMPORTANT_INFORMATION_URL } from "../../shared/routing/routes.config";
import { StyledNotificationTypography } from "../styled";
import { FormProps } from "./types";

interface ErrorContextType {
  attempts: number;
  attemptsExhausted: boolean;
  quoteNotFound: boolean;
}

const ErrorContext = React.createContext<ErrorContextType | undefined>(undefined);
ErrorContext.displayName = "ErrorContext";

const ErrorContainer: React.FC<PropsWithChildren<ErrorContextType>> = memo(({ children, ...value }) => {
  return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>;
});

const useErrorContext = () => {
  const context = React.useContext(ErrorContext);
  if (!context) {
    throw new Error(
      `Retrieve quote error container compound components cannot be rendered outside the Toggle component`,
    );
  }
  return context;
};

const AttempsExhausted = ({ children }: { children: React.ReactNode }) => {
  const { attemptsExhausted } = useErrorContext();
  return <>{attemptsExhausted ? children : null}</>;
};

const CantFindQuote = ({ children }: { children: React.ReactNode }) => {
  const { quoteNotFound, attemptsExhausted } = useErrorContext();
  return <>{quoteNotFound && !attemptsExhausted ? children : null}</>;
};

export const RetrieveQuoteForm: React.FC<FormProps> = ({
  form,
  attempts,
  attemptsExhausted,
  suburbs,
  onSubmit,
  quoteNotFound,
  setQuoteNotFound,
}) => {
  const { initialiseFormotiv, formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);

  useEffect(() => {
    initialiseFormotiv();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid container sx={{ justifyContent: "center", maxWidth: { sm: "600px" } }}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(formotivOnSubmitWrapper(onSubmit))} action="#" noValidate>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h2" id="header" data-testid="header">
                  Let's retrieve your quote
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <QuoteNumberInput
                  sublabel="You'll find this on your quote summary email."
                  prefix={QuotePrefix.QTE}
                  disabled={attemptsExhausted}
                  onChange={() => setQuoteNotFound(false)}
                />
              </Grid>
              <Grid item xs={12} sx={{ paddingBottom: "16px" }}>
                <SuburbInput
                  sublabel="This is the suburb where you keep your boat."
                  options={suburbs}
                  placeholder="Enter suburb"
                  errorMessage="Please enter a suburb"
                  disabled={attemptsExhausted}
                  onChange={() => setQuoteNotFound(false)}
                />
              </Grid>
              <Grid item xs={12} sx={{ paddingBottom: "8px" }}>
                <Typography variant="body2" id="please-note" data-testid="please-note">
                  Please note that your quote may have changed. If there are any updates, we'll retrieve your latest
                  quote.
                </Typography>
              </Grid>
              <ErrorContainer attempts={attempts} attemptsExhausted={attemptsExhausted} quoteNotFound={quoteNotFound}>
                <CantFindQuote>
                  <Grid item xs={12} sx={{ paddingBottom: "8px", marginTop: "16px" }}>
                    <RacwaCardNotification
                      id="cant-find-quote-notification"
                      title="Your quote number and suburb don't match"
                      severity="error"
                    >
                      <StyledNotificationTypography paragraph variant="body2" id="cant-find-quote-notification-body-2">
                        Please check your details and try again or{" "}
                        <Link href={UTILITY_IMPORTANT_INFORMATION_URL} id="get-a-new-quote" rel="noreferrer noopener">
                          get a new quote
                        </Link>
                        {"."}
                      </StyledNotificationTypography>
                    </RacwaCardNotification>
                  </Grid>
                </CantFindQuote>
                <AttempsExhausted>
                  <Grid item xs={12} sx={{ paddingBottom: "8px", marginTop: "16px" }}>
                    <RacwaCardNotification
                      id="attempts-exhausted-notification"
                      title="Sorry, we've hit a roadblock"
                      severity="error"
                    >
                      <StyledNotificationTypography
                        paragraph
                        variant="body2"
                        id="attempts-exhausted-notification-body-2"
                      >
                        Unfortunately, we can't retrieve your quote. Please call us on{" "}
                        <InsurancePhoneNumberLink id="link-1" /> with your quote number.
                      </StyledNotificationTypography>
                    </RacwaCardNotification>
                  </Grid>
                </AttempsExhausted>
              </ErrorContainer>
              <Grid item xs={12}>
                <CallToActionButton
                  id="btn-retrieve-quote"
                  name="btn-retrieve-quote"
                  type="submit"
                  data-testid="btn-retrieve-quote"
                  role="button"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={attemptsExhausted}
                >
                  Retrieve quote
                </CallToActionButton>
                <FaqCard />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  );
};

export default RetrieveQuoteForm;
