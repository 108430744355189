import { Grid } from "@mui/material";
import { useGetSessionState } from "raci-react-library";
import { useNavigate } from "react-router-dom";
import { SkipperExperience } from "../../../../../../../shared/hooks/useApiClient/ClientProxy.generated";
import {
  PURCHASE_YOUR_DETAILS_PAGE_URL,
  PurchasePageNames,
  PurchaseRoutes,
  QUOTE_ABOUT_YOU_PAGE_URL,
  QuoteRoutes,
} from "../../../../../../../shared/routing/routes.config";
import { AboutYouState } from "../../../../../../AboutYou/types";
import { YourDetailsState } from "../../../../../../YourDetails/types";
import { StyledTypography } from "../../../styles";
import SummaryItem from "../../SummaryItem";

export const YourDetailsPolicySummaryItem: React.FC = () => {
  const aboutYouState = useGetSessionState<AboutYouState>(QuoteRoutes.AboutYou);
  const yourDetailsState = useGetSessionState<YourDetailsState>(PurchaseRoutes.YourDetails);
  const navigate = useNavigate();

  const formattedBirthDate =
    aboutYouState.dateOfBirth && new Date(aboutYouState.dateOfBirth).toLocaleDateString("en-AU");

  const getClaimsText = (num?: number) => {
    if (!num || num < 1) {
      return "No claims";
    }
    return num + (num > 1 ? " claims" : " claim");
  };

  const getSkippersText = (skippersExperience: SkipperExperience) => {
    if (skippersExperience === SkipperExperience.Less_than_1_year) {
      return "Skipper's ticket held for less than 1 year";
    } else if (skippersExperience === SkipperExperience._12) {
      return "Skipper's ticket held for 1-2 years";
    } else if (skippersExperience === SkipperExperience._23) {
      return "Skipper's ticket held for 2-3 years";
    } else if (skippersExperience === SkipperExperience._3_) {
      return "Skipper's ticket held for 3+ years";
    } else {
      return "No Skipper's ticket held";
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SummaryItem
          title={PurchasePageNames.YourDetails}
          name={PurchaseRoutes.YourDetails}
          onClick={() => navigate(PURCHASE_YOUR_DETAILS_PAGE_URL)}
        >
          <StyledTypography
            variant="body2"
            id={`${PurchaseRoutes.YourDetails}-full-name-text`}
            data-testid={`${PurchaseRoutes.YourDetails}-full-name-text`}
          >
            {yourDetailsState.title} {yourDetailsState.firstName} {yourDetailsState.lastName}
          </StyledTypography>
          <StyledTypography
            variant="body2"
            id={`${PurchaseRoutes.YourDetails}-contact-number-text`}
            data-testid={`${PurchaseRoutes.YourDetails}-contact-number-text`}
          >
            {yourDetailsState.contactNumber}
          </StyledTypography>
          <StyledTypography
            variant="body2"
            id={`${PurchaseRoutes.YourDetails}-email-text`}
            data-testid={`${PurchaseRoutes.YourDetails}-email-text`}
          >
            {yourDetailsState.email}
          </StyledTypography>
          <StyledTypography
            variant="body2"
            id={`${PurchaseRoutes.YourDetails}-address-text`}
            data-testid={`${PurchaseRoutes.YourDetails}-address-text`}
          >
            {yourDetailsState.mailingAddress?.displayAddress}
          </StyledTypography>
        </SummaryItem>
      </Grid>
      <Grid item xs={12}>
        <SummaryItem name={PurchaseRoutes.YourDetails} onClick={() => navigate(QUOTE_ABOUT_YOU_PAGE_URL)}>
          <StyledTypography
            variant="body2"
            id={`${PurchaseRoutes.YourDetails}-dob-text`}
            data-testid={`${PurchaseRoutes.YourDetails}-dob-text`}
          >
            Date of birth {formattedBirthDate}
          </StyledTypography>
          <StyledTypography
            variant="body2"
            id={`${PurchaseRoutes.YourDetails}-skippers-experience-text`}
            data-testid={`${PurchaseRoutes.YourDetails}-skippers-experience-text`}
          >
            {getSkippersText(aboutYouState.skippersExperience)}
          </StyledTypography>
          <StyledTypography
            variant="body2"
            id={`${PurchaseRoutes.YourDetails}-claims-history-text`}
            data-testid={`${PurchaseRoutes.YourDetails}-claims-history-text`}
          >
            {getClaimsText(aboutYouState.claimsHistory?.length)} in the last 3 years
          </StyledTypography>
        </SummaryItem>
      </Grid>
    </Grid>
  );
};
