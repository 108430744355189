import { RacwaCardNotification } from "@racwa/react-components";
import { InsurancePhoneNumberLink } from "raci-react-library";
import { StyledNotificationTypography } from "../../../../../views/styled";

const AgreedValueCard = () => {
  return (
    <RacwaCardNotification id="amount-notification" title="Please enter a value under $150,000" severity="error">
      <StyledNotificationTypography paragraph variant="body2" id="amount-notification-body-1">
        If your boat is valued over $150,000, please call us on <InsurancePhoneNumberLink id="link-1" />.
      </StyledNotificationTypography>
    </RacwaCardNotification>
  );
};

export default AgreedValueCard;
