import { Grid, Typography } from "@mui/material";
import { SuburbInput, YesNoButtonGroup, trackCustomFormotivInput, useFormotiv } from "raci-react-library";
import { useEffect } from "react";
import { FormProvider, useWatch } from "react-hook-form";
import { CallToActionButton } from "../../shared/components/CallToActionButton";
import FaqCard from "../../shared/components/FaqCard";
import { FORMOTIV_CONFIG } from "../../shared/constants";
import { getYesNoLabel } from "../../shared/utils/helpers";
import GoldenRuleCard from "./components/GoldenRuleCard";
import { MotorTypeInput } from "./components/MotorTypeInput";
import SecuritySystemInput from "./components/SecuritySystemInput";
import { FormProps } from "./types";

const keptInGarageName = "keptInGarage";
const suburbName = "suburb";

export const Form: React.FC<FormProps> = ({
  form,
  values,
  onSubmit,
  data,
  showGoldenRuleCard,
  setShowGoldenRuleCard,
}) => {
  const { handleSubmit, control } = form;
  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);
  const state = useWatch({ control });

  useEffect(() => {
    setShowGoldenRuleCard(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(formotivOnSubmitWrapper(onSubmit))} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2" id="header" data-testid="header">
              More about your boat
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SuburbInput
              label="Suburb where you keep your boat"
              name={suburbName}
              options={data.suburbs}
              placeholder="Enter suburb"
              errorMessage="Please enter a suburb"
              onChange={(value) => trackCustomFormotivInput(suburbName, "autocomplete", value)}
            />
          </Grid>
          <Grid item xs={12}>
            <YesNoButtonGroup
              name={keptInGarageName}
              label="Boat kept in a garage"
              id="kept-in-garage"
              data-testid="kept-in-garage"
              onChange={(_, value) => trackCustomFormotivInput(getYesNoLabel(keptInGarageName, value), "button", value)}
            />
          </Grid>
          <Grid item xs={12}>
            <MotorTypeInput data={data.motorTypes} />
          </Grid>

          <Grid item xs={12}>
            <SecuritySystemInput options={data.securitySystemOptions} />
          </Grid>

          {showGoldenRuleCard ? (
            <Grid item xs={12} sx={{ margin: "16px 0px 0px 0px" }}>
              <GoldenRuleCard />
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <CallToActionButton
              type="submit"
              data-testid="submit"
              color="primary"
              variant="contained"
              disabled={false}
              fullWidth
            >
              View quote
            </CallToActionButton>
            <FaqCard />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Form;
