import { createFilterOptions } from "@mui/material/useAutocomplete";
import { fieldTouched, gtm } from "@racwa/analytics";
import { RacwaAutocomplete, RacwaTextInput } from "@racwa/react-components";
import { trackCustomFormotivInput } from "raci-react-library";
import { useController, useFormContext } from "react-hook-form";
import { ReferenceDataOption } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { YourBoatState } from "../../types";

const boatMakeLabel = "Make";
const boatMakeName = "make";
const otherMakeName = "otherMake";
const other = "Other";
const otherMakeMessage = "Please enter your boat's make";
const otherMakeMaxLength = 33;

export interface BoatMakeProps {
  options?: Array<ReferenceDataOption>;
}

export const BoatMake: React.FC<BoatMakeProps> = ({ options = [] }) => {
  const { control, resetField, setValue } = useFormContext<YourBoatState>();

  type TOptions = typeof options extends Array<infer T> ? T : never;
  const _filterOptions = createFilterOptions<TOptions>({ matchFrom: "start" });

  type TFilterOptions = React.ComponentProps<typeof RacwaAutocomplete<TOptions>>["filterOptions"];
  const filterOptions: TFilterOptions = (options, state) => {
    const results = _filterOptions(options, state);
    if (!results.some((e) => e.externalCode === other)) {
      return [...results, { externalCode: other, description: other }];
    }
    return results;
  };

  const {
    field: { ref, onBlur, onChange, value },
    fieldState: { error },
  } = useController({
    control,
    name: boatMakeName,
    rules: {
      required: { value: true, message: "Please select an option" },
    },
  });

  const {
    field: { ref: inputRef, onBlur: inputOnBlur, onChange: inputOnChange, value: inputValue, ...renderProps },
    fieldState: { error: inputError },
  } = useController({
    control,
    name: otherMakeName,
    rules: {
      required: { value: value?.externalCode === other, message: otherMakeMessage },
      onBlur: () => gtm(fieldTouched(otherMakeMessage)),
      validate: (v) => {
        if (value?.externalCode !== other) {
          return true;
        }
        return v?.trim() !== "" || otherMakeMessage;
      },
    },
  });

  return (
    <>
      <RacwaAutocomplete
        id="boat-make-autocomplete"
        label={boatMakeLabel}
        sublabel="If your make isn't listed, select 'Other'."
        placeholder="Start typing your make"
        options={options}
        filterOptions={filterOptions}
        getOptionLabel={(option) => option.description}
        isOptionEqualToValue={(option, value) => option.externalCode === value.externalCode}
        fullWidth
        autoComplete
        autoHighlight
        clearOnEscape
        value={value}
        error={!!error}
        helperText={error?.message}
        onChange={(_, value) => {
          if (value?.externalCode !== other) {
            resetField(otherMakeName);
          }
          trackCustomFormotivInput(boatMakeName, "autocomplete", value);
          onChange(value);
        }}
        onBlur={() => {
          onBlur();
          gtm(fieldTouched(boatMakeLabel));
        }}
        data-hj-suppress
        inputRef={ref}
      />
      {value?.externalCode === other && (
        <RacwaTextInput
          {...renderProps}
          id="other-boat-make-autocomplete"
          label="Please enter your boat's make"
          placeholder="Start typing your make"
          value={inputValue}
          error={!!inputError}
          helperText={inputError?.message}
          inputProps={{
            maxLength: otherMakeMaxLength,
          }}
          onChange={(e) => inputOnChange(e)}
          onBlur={(e) => {
            const value = e.currentTarget.value;
            setValue(otherMakeName, value.trim());
            inputOnBlur();
          }}
          inputRef={inputRef}
        />
      )}
    </>
  );
};

export default BoatMake;
