import { useGetSessionState, useSessionStateInitialiser } from "raci-react-library";
import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { atom, useRecoilState } from "recoil";
import { ImportantInformationState } from "../../../views/ImportantInformation/types";
import { MoreBoatState } from "../../../views/MoreBoat/types";
import { PaymentData as PaymentDataValues } from "../../../views/Payment/types";
import { YourQuoteState } from "../../../views/YourQuote/types";
import { SESSION_KEY_PREFIX } from "../../constants";
import {
  PurchaseRoutes,
  QuoteRoutes,
  RouteInformation,
  UTILITY_IMPORTANT_INFORMATION_URL,
  UtilityRoutes,
  allRoutes,
  purchaseRoutesInfo,
  quoteRoutes,
  quoteRoutesInfo,
  quoteToPurchaseRoutes,
} from "../routes.config";

interface InternalRouteStates {
  hasReachedPurchaseFlow: boolean;
}

const sessionStorageKey = `${SESSION_KEY_PREFIX}Internal_Route_State`;
const defaultInternalRouteStates: InternalRouteStates = JSON.parse(
  sessionStorage.getItem(sessionStorageKey) ?? JSON.stringify({ hasReachedPurchaseFlow: false }),
) as InternalRouteStates;

const internalRouteStates = atom<InternalRouteStates>({
  key: "InternalRouteStates",
  default: defaultInternalRouteStates,
});

export interface StepInformation {
  name: string;
  path: string;
}

export interface UseRoutesResults {
  isQuoteOrPurchaseFlow: boolean;
  isImportantInformation: boolean;
  hasReachedPurchaseFlow: boolean;
  steps: StepInformation[];
  formRoutes: RouteInformation[];
  activeStepIndex?: number;
  totalStepCount?: number;
  showPurchaseFlow: () => void;
  isCompleted: boolean;
  isSuccessful: boolean;
  lockdownPreviousPage: boolean;
  isQuoteCompleted: boolean;
  navigateToPreviousStep?: () => void;
  importantInformationConfirmed: boolean;
}

export const useRoutes = (): UseRoutesResults => {
  const location = useLocation();
  const navigate = useNavigate();

  const initialiseSessionStates = useSessionStateInitialiser();

  useEffect(() => {
    initialiseSessionStates(
      allRoutes.map(({ key, path }) => ({
        page: key,
        path,
      })),
    );
  }, [initialiseSessionStates, location.pathname]);

  const [internalState, setInternalState] = useRecoilState(internalRouteStates);
  const { lockdownPreviousPage, isCompleted, isSuccessful } = useGetSessionState<PaymentDataValues>(
    PurchaseRoutes.Payment,
  );
  const { hasReachedPurchaseFlow } = internalState;
  const isQuoteOrPurchaseFlow = allRoutes.filter((item) => item.path === location.pathname).length > 0;

  const { isCompleted: isQuotePageCompleted } = useGetSessionState<YourQuoteState>(QuoteRoutes.YourQuote);
  const { importantInformationConfirmed } = useGetSessionState<ImportantInformationState>(
    UtilityRoutes.ImportantInformation,
  );

  const { quote } = useGetSessionState<MoreBoatState>(QuoteRoutes.MoreBoat);
  const hasQuoteNumber = !!quote?.quoteNumber;

  const steps: StepInformation[] = useMemo(() => {
    if (!isQuoteOrPurchaseFlow) return [];
    return hasQuoteNumber ? quoteToPurchaseRoutes : quoteRoutes;
  }, [hasQuoteNumber, isQuoteOrPurchaseFlow]);

  const activeStepIndex = isQuoteOrPurchaseFlow
    ? steps.findIndex((item) => item.path === location.pathname)
    : undefined;
  const totalStepCount = isQuoteOrPurchaseFlow ? steps.length : undefined;

  const showPurchaseFlow = useCallback(() => {
    if (!internalState.hasReachedPurchaseFlow) {
      const newState = { ...internalState, hasReachedPurchaseFlow: true };
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(newState));
      setInternalState(newState);
    }
  }, [setInternalState, internalState]);

  const navigateToPreviousStep = useMemo(() => {
    const stopBacktrackSteps = [
      quoteRoutesInfo[QuoteRoutes.LetsStart],
      purchaseRoutesInfo[PurchaseRoutes.Confirmation],
    ];
    const isAllowedToBacktrack = (path: string) => !stopBacktrackSteps.some((step) => step.path === path);

    return isQuoteOrPurchaseFlow && (activeStepIndex ?? 0) > 0 && isAllowedToBacktrack(location.pathname)
      ? () => navigate(steps[(activeStepIndex ?? 0) - 1].path, { replace: true })
      : undefined;
  }, [isQuoteOrPurchaseFlow, activeStepIndex, navigate, steps, location.pathname]);

  const isImportantInformation = location.pathname === UTILITY_IMPORTANT_INFORMATION_URL;

  return {
    isQuoteOrPurchaseFlow,
    isImportantInformation,
    hasReachedPurchaseFlow,
    steps,
    formRoutes: quoteToPurchaseRoutes,
    activeStepIndex,
    totalStepCount,
    lockdownPreviousPage: lockdownPreviousPage ?? false,
    isCompleted: isCompleted ?? false,
    isSuccessful: isSuccessful ?? false,
    isQuoteCompleted: isQuotePageCompleted ?? false,
    showPurchaseFlow,
    navigateToPreviousStep,
    importantInformationConfirmed,
  };
};

export default useRoutes;
