import { useEffect, useState } from "react";
import { SESSION_KEY_PREFIX } from "../../constants";
import useApiClient from "../useApiClient";

// Note: Even though SESSION_KEY_PREFIX is defined as a const
// and should have been initialised prior to
// this module running, it is appearing as undefined (only for this module)
const sessionPrefix = SESSION_KEY_PREFIX || "RAC_BOAT_";
export const SESSION_ID_KEY = `${sessionPrefix}SessionId`;

export const useCreateSession = (): "loading" | "successful" | "error" => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const client = useApiClient();

  useEffect(() => {
    let isCancelled = false;

    const createSession = async () => {
      setIsError(false);
      try {
        if (!isCancelled && !sessionStorage.getItem(SESSION_ID_KEY)) {
          const response = await client.createsession();
          sessionStorage.setItem(SESSION_ID_KEY, response.result);
          if (response.status !== 200) {
            setIsError(true);
          }
        }
      } catch {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    createSession();

    return () => {
      isCancelled = true;
    };
  }, [client]);

  return isLoading ? "loading" : isError ? "error" : "successful";
};

export default useCreateSession;
