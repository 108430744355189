import { Check } from "@mui/icons-material";
import { Link, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { event, gtm } from "@racwa/analytics";
import { StyledCard, StyledTypography } from "../../styled";

const RacWaWhatsIncluded: React.FC = () => {
  return (
    <Grid container>
      <StyledCard background="white">
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2" gutterBottom paragraph sx={{ fontWeight: 400, fontSize: "18px" }}>
              What's included
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={1}>
            <Check fontSize="small" />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body2" gutterBottom paragraph>
              Cover for accidental damage, malicious damage, fire, theft, storm and flood.
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Check fontSize="small" />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body2" gutterBottom paragraph>
              Cover anywhere in Australia and up to 200 nautical miles from the Australian coastline.
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Check fontSize="small" />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body2" gutterBottom paragraph>
              Cover for personal belongings up to $1500 if this is part of an eligible claim.
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Check fontSize="small" />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body2" gutterBottom paragraph>
              Cover for bodily injury or accidental death to another person or damage to their property.
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Check fontSize="small" />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body2" gutterBottom paragraph>
              New boat replacement cover.
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Check fontSize="small" />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body2" gutterBottom paragraph>
              Local claims team, available seven days a week.
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Check fontSize="small" />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body2" gutterBottom paragraph>
              And much more!
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <StyledTypography variant="body2" gutterBottom paragraph>
              This is a summary. See the{" "}
              <Link
                href={process.env.REACT_APP_PDS_PED_URL}
                rel="noreferrer noopener"
                target="_blank"
                onClick={() => gtm(event("Product Disclosure Statement"))}
              >
                Product Disclosure Statement
              </Link>{" "}
              for more information.
            </StyledTypography>
          </Grid>
        </Grid>
      </StyledCard>
    </Grid>
  );
};

export default RacWaWhatsIncluded;
