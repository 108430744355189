import { PropsWithChildren, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { UtilityPageNames, allRoutes } from "../routes.config";
import { useRoutes } from "../useRoutes";

const defaultTitle = process.env.REACT_APP_SITE_TITLE || "";
const envPrefix = process.env.REACT_APP_ENVIRONMENT !== "PRD" ? `**${process.env.REACT_APP_ENVIRONMENT}** - ` : "";
const titlePrefix = process.env.REACT_APP_NAME?.replace(" insurance", " quote");

const utilityPagesWithTitlePrefix = new Set([
  UtilityPageNames.ImportantInformation,
  UtilityPageNames.RetrieveQuote,
] as Array<string>);

const createPageTitle = (step: number | undefined, title: string) => {
  return step !== undefined && (step ?? 0) >= 0
    ? `${envPrefix}${titlePrefix} - Step ${step + 1} - ${title}`
    : utilityPagesWithTitlePrefix.has(title)
      ? `${envPrefix}${titlePrefix} - ${title}`
      : `${envPrefix}${title}`;
};

export const TitleGuard: React.FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const { activeStepIndex } = useRoutes();

  useEffect(() => {
    if (document) {
      const route = allRoutes.filter((item) => item.path === location.pathname)[0];
      document.title = route?.name ? createPageTitle(activeStepIndex, route.name) : defaultTitle;
    }
  }, [activeStepIndex, location.pathname]);

  return <>{children}</>;
};
