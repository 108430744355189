import { Check } from "@mui/icons-material";
import { Button, Collapse, Link, TextField, Typography } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { RacwaCardNotification } from "@racwa/react-components";
import { EmailInput, useSessionState } from "raci-react-library";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { EmailQuoteRequest } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { UTILITY_RETRIEVE_QUOTE_URL } from "../../../../shared/routing/routes.config";
import { YourQuoteState } from "../../types";
import EmailQuoteButton from "../EmailQuoteButton";
import SendQuoteButton from "../SendQuoteButton";

export interface EmailQuoteFormProps {
  open?: boolean | undefined;
  onSubmit: (value: EmailQuoteRequest) => Promise<boolean>;
  quoteNumber: string;
}

export const EmailQuoteForm: React.FC<EmailQuoteFormProps> = ({ open = false, onSubmit, quoteNumber }) => {
  const { handleSubmit, getValues, setValue } = useFormContext<EmailQuoteRequest>();
  const emailId = `input-email`;
  const [isOpen, setIsOpen] = useState<boolean>(open);
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const [isEmailError, setIsEmailError] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [state] = useSessionState<YourQuoteState>();

  const handleCopyQuoteNumber = () => {
    navigator.clipboard.writeText(quoteNumber).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    });
  };

  const handleSendEmail = async () => {
    const request = {
      ...getValues(),
      ...state,
    };
    const result = await onSubmit(request as EmailQuoteRequest);
    setIsEmailSent(result);
    setIsEmailError(!result);
  };

  return (
    <>
      <EmailQuoteButton
        onClick={() => {
          setIsOpen(!isOpen);
          gtm(event("Email quote"));
        }}
        open={isOpen}
      />
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <EmailInput
          sx={{ marginBottom: 2 }}
          id={emailId}
          setValue={setValue}
          defaultValue={""}
          onChange={() => {
            setIsEmailError(false);
            setIsEmailSent(false);
          }}
          onBlur={() => gtm(fieldTouched("Email"))}
        />
        {isEmailError ? (
          <div style={{ paddingTop: "18px" }}>
            <RacwaCardNotification
              id="email-quote-error-notification"
              title="Sorry, we've hit a roadblock"
              severity="error"
            >
              <Typography
                paragraph
                variant="body2"
                id="email-quote-error-notification-body-1"
                sx={{ fontSize: "16px", fontWeight: 300, marginBottom: "0px" }}
              >
                We couldn't email your quote because of technical issues. Please copy your quote number to{" "}
                <Link href={UTILITY_RETRIEVE_QUOTE_URL} id="email-quote-error-retrieve-link" rel="noreferrer noopener">
                  retrieve your quote
                </Link>{" "}
                later. <br /> Or select 'Get policy' to purchase a policy.
              </Typography>

              <TextField
                variant="outlined"
                value={quoteNumber}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#FFD100",
                  },
                  "& .MuiInputAdornment-root": {
                    paddingRight: "0px",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{ minWidth: "128px", zIndex: 1 }}
                      onClick={handleCopyQuoteNumber}
                      startIcon={isCopied && <Check />}
                    >
                      {isCopied ? "Copied" : "Copy"}
                    </Button>
                  ),
                }}
              />
            </RacwaCardNotification>
          </div>
        ) : (
          <SendQuoteButton emailSent={isEmailSent} onClick={handleSubmit(handleSendEmail)} />
        )}
      </Collapse>
    </>
  );
};

export default EmailQuoteForm;
