import { useSessionState, useSetBackdrop } from "raci-react-library";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ApiException, NumberOfClaims } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import useReferenceData from "../../../../shared/hooks/useReferenceData";
import {
  QUOTE_BOAT_TYPE_PAGE_URL,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
} from "../../../../shared/routing/routes.config";
import { toArray } from "../../../../shared/utils/helpers";
import { AboutYouState, FormProps } from "../../types";

export const useAboutYou = (): FormProps => {
  const navigate = useNavigate();
  const setIsOpen = useSetBackdrop();
  const [aboutYouState, setAboutYouState] = useSessionState<AboutYouState>();
  const { claimTypes } = useReferenceData();
  const numberOfClaims = toArray(NumberOfClaims);

  // Date of birth becomes a string
  const state = {
    ...aboutYouState,
    dateOfBirth: aboutYouState.dateOfBirth && new Date(aboutYouState.dateOfBirth),
  };

  const redirectToSystemUnavailable = (status: number) => {
    navigate(`${UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL}/${status}`);
  };

  const form = useForm<AboutYouState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: state,
    shouldUnregister: true,
  });

  useEffect(() => {
    if (claimTypes.length < 1) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
    return () => setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimTypes]);

  const onSubmit = async (value: AboutYouState) => {
    if (value.numberOfClaims !== NumberOfClaims._6_) {
      try {
        setIsOpen(true);
        return new Promise<void>(() => {
          setAboutYouState({ ...value, isCompleted: true });
          navigate(QUOTE_BOAT_TYPE_PAGE_URL);
        });
      } catch (exception) {
        const errorResponse = exception as ApiException;
        redirectToSystemUnavailable(errorResponse.status);
      } finally {
        setIsOpen(false);
      }
    }
  };

  return {
    form,
    data: { claimTypes, numberOfClaims },
    onSubmit,
  };
};

export default useAboutYou;
