import { ClickAwayListener, Grid, Typography } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { YesNoButtonGroup, trackCustomFormotivInput, useFormotiv } from "raci-react-library";
import { useState } from "react";
import { FormProvider, useWatch } from "react-hook-form";
import { CallToActionButton } from "../../shared/components/CallToActionButton";
import FaqCard from "../../shared/components/FaqCard";
import { FORMOTIV_CONFIG } from "../../shared/constants";
import { getYesNoLabel } from "../../shared/utils/helpers";
import CommercialUseCard from "./components/CommercialUseCard";
import HighValueCard from "./components/HighValueCard";
import PersonalWatercraftCard from "./components/PersonalWatercraftCard";
import WaterStoredCard from "./components/WaterStoredCard";
import { FormProps } from "./types";

const waterStoredLabel = "Usually stored in the water?";
const waterStoredName = "waterStored";
const commercialUseLabel = "Used for business or commercial purposes?";
const commercialUseName = "commercialUse";
const personalWatercraftLabel = "A houseboat or personal watercraft, e.g. jet ski?";
const personalWatercraftName = "personalWatercraft";
const highValueLabel = "Valued at more than $150,000?";
const highValueName = "highValue";

export const Form: React.FC<FormProps> = ({ form, waterStoredTooltipProps, commercialUseTooltipProps, onSubmit }) => {
  const { handleSubmit } = form;
  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);

  const [openWaterStoredTooltip, setOpenWaterStoredTooltip] = useState(false);
  const [openCommercialUseTooltip, setOpenCommercialUseTooltip] = useState(false);

  const waterStored = useWatch({ control: form.control, name: waterStoredName });
  const commercialUse = useWatch({ control: form.control, name: commercialUseName });
  const personalWatercraft = useWatch({ control: form.control, name: personalWatercraftName });
  const highValue = useWatch({ control: form.control, name: highValueName });

  const handleCloseTooltips = () => {
    if (openWaterStoredTooltip) setOpenWaterStoredTooltip(false);
    if (openCommercialUseTooltip) setOpenCommercialUseTooltip(false);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(formotivOnSubmitWrapper(onSubmit))} action="#">
        <ClickAwayListener onClickAway={() => handleCloseTooltips()}>
          {/* SPK-2952: Added onClick to the Grid container due to a bug with ClickAwayListener not working within the grids. */}
          <Grid container spacing={1} onClick={() => handleCloseTooltips()}>
            <Grid item xs={12}>
              <Typography variant="h2" id="header" data-test-id="header">
                Let's start your quote
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "24px", marginBottom: "8px" }}>
              <Typography variant="h3" id="subheader" data-testid="subheader">
                Is your boat...
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <YesNoButtonGroup
                name={waterStoredName}
                label={waterStoredLabel}
                id={waterStoredName}
                data-testid={waterStoredName}
                onChange={(_, value) =>
                  trackCustomFormotivInput(getYesNoLabel(waterStoredName, value), "button", value)
                }
                tooltipProps={{
                  ...waterStoredTooltipProps,
                  open: openWaterStoredTooltip,
                  onClickClose: () => setOpenWaterStoredTooltip(false),
                  onClick: () => {
                    setOpenWaterStoredTooltip(!openWaterStoredTooltip);
                    setOpenCommercialUseTooltip(false);
                    gtm(event("Tooltip: Usually stored in the water"));
                  },
                }}
              />
            </Grid>
            {waterStored ? (
              <Grid item xs={12}>
                <WaterStoredCard />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <YesNoButtonGroup
                name={commercialUseName}
                label={commercialUseLabel}
                id={commercialUseName}
                data-testid={commercialUseName}
                onChange={(_, value) =>
                  trackCustomFormotivInput(getYesNoLabel(commercialUseName, value), "button", value)
                }
                tooltipProps={{
                  ...commercialUseTooltipProps,
                  open: openCommercialUseTooltip,
                  onClickClose: () => setOpenCommercialUseTooltip(false),
                  onClick: () => {
                    setOpenCommercialUseTooltip(!openCommercialUseTooltip);
                    setOpenWaterStoredTooltip(false);
                    gtm(event("Tooltip: Used for business or commercial purposes"));
                  },
                }}
              />
            </Grid>
            {commercialUse ? (
              <Grid item xs={12}>
                <CommercialUseCard />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <YesNoButtonGroup
                name={personalWatercraftName}
                label={personalWatercraftLabel}
                id={personalWatercraftName}
                data-testid={personalWatercraftName}
                onChange={(_, value) =>
                  trackCustomFormotivInput(getYesNoLabel(personalWatercraftName, value), "button", value)
                }
              />
            </Grid>
            {personalWatercraft ? (
              <Grid item xs={12}>
                <PersonalWatercraftCard />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <YesNoButtonGroup
                name={highValueName}
                label={highValueLabel}
                id={highValueName}
                data-testid={highValueName}
                onChange={(_, value) => trackCustomFormotivInput(getYesNoLabel(highValueName, value), "button", value)}
              />
            </Grid>
            {highValue ? (
              <Grid item xs={12}>
                <HighValueCard />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <CallToActionButton type="submit" data-testid="submit" color="primary" variant="contained" fullWidth>
                Next
              </CallToActionButton>
              <FaqCard />
            </Grid>
          </Grid>
        </ClickAwayListener>
      </form>
    </FormProvider>
  );
};

export default Form;
