import { MenuItem } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { RacwaSelect } from "@racwa/react-components";
import { trackCustomFormotivInput } from "raci-react-library";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { BOAT_INSURANCE_MINIMUM_AGE } from "../../../../shared/constants";
import { SkipperExperience } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { toArray } from "../../../../shared/utils/helpers";

const skipperExperienceName = "skippersExperience";
const numberOfYearsHeldLabel = "Number of years skipper's ticket held";

export interface SkippersExperienceProps {
  dateOfBirth?: Date;
  value?: SkippersExperienceValue;
  calculate: boolean;
}

export interface SkippersExperienceValue {
  skippersExperience: string;
}

export const SkippersExperience: React.FC<SkippersExperienceProps> = ({ value = "", dateOfBirth, calculate }) => {
  const { setValue, control } = useFormContext();

  const age = dateOfBirth && new Date().getFullYear() - dateOfBirth.getFullYear();
  const skippersExperienceArray = toArray(SkipperExperience);
  const skippersExperienceYears =
    age && age <= BOAT_INSURANCE_MINIMUM_AGE
      ? skippersExperienceArray.filter((x) => x !== SkipperExperience._3_)
      : skippersExperienceArray;

  //  Reset the user selection if their age changes
  //  so we aren't left with an out of range value in the event they select 3+ then change their age to 16
  useEffect(() => {
    if (calculate) {
      setValue(skipperExperienceName, "");
    }
  }, [age, calculate, setValue]);

  return (
    <Controller
      control={control}
      name={skipperExperienceName}
      aria-label={"Skippers Experience"}
      defaultValue={value ?? ""}
      rules={{
        required: { value: true, message: "Please select one" },
      }}
      render={({ field: { ref, value, onBlur, onChange: onComponentChange, ...props }, fieldState: { error } }) => (
        <RacwaSelect
          value={value}
          id="about-you-skippers-select"
          data-testid="about-you-skippers-select"
          label={numberOfYearsHeldLabel}
          fullWidth
          displayEmpty
          onChange={(e) => {
            const experience = e.target.value as string;
            trackCustomFormotivInput(skipperExperienceName, "select", experience);
            onComponentChange && onComponentChange(experience);
            value && setValue(props.name, experience);
          }}
          onOpen={() => gtm(fieldTouched(numberOfYearsHeldLabel))}
          error={!!error}
          helperText={error?.message || ""}
          inputRef={ref}
        >
          <MenuItem key="disabled" disabled selected hidden value="">
            Please select one
          </MenuItem>
          {skippersExperienceYears.map((value, index) => {
            return (
              <MenuItem key={index} value={value}>
                {value}
              </MenuItem>
            );
          })}
        </RacwaSelect>
      )}
    />
  );
};

export default SkippersExperience;
