import { FormotivConfigOptions } from "raci-react-library/dist/hooks/useFormotiv/types";

export const NON_BREAKING_SPACE = "\u00a0";
export const TIMEOUT_30_MINUTES_MS = 1800000;
export const BOAT_INSURANCE_MINIMUM_AGE = 16;

export const SESSION_KEY_PREFIX = "RAC_BOAT_";
export const SESSION_KEY_PAGE_PREFIX = `${SESSION_KEY_PREFIX}PAGE_`;
export const SESSION_KEY_ATOM_TIMEOUT = `${SESSION_KEY_PREFIX}ATOM_TIMEOUT`;

export const CXONE_RAC_BUSINESS_NUMBER = 4599642;
export const CXONE_RAC_POC = "645ee603-befc-4991-b9db-f9382295098f";

export const FORMOTIV_CONFIG = {
  appId: process.env.REACT_APP_FORMOTIV_APP_ID,
  consoleUrl: process.env.REACT_APP_FORMOTIV_CONSOLE_URL,
  apiUrl: process.env.REACT_APP_FORMOTIV_API_URL,
  environment: process.env.REACT_APP_ENVIRONMENT,
  logLevel: process.env.REACT_APP_FORMOTIV_LOGGING ?? undefined,
} as FormotivConfigOptions;
