import { SessionTimeoutDialog } from "../../shared/components/SessionTimeoutDialog";
import { Form } from "./Form";
import { useYourQuote } from "./hooks/useYourQuote";

export const YourQuote: React.FC = () => {
  const props = useYourQuote();

  return (
    <>
      <Form {...props} />
      <SessionTimeoutDialog />
    </>
  );
};

export default YourQuote;
