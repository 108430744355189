import { Grid, Typography, useMediaQuery } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { theme } from "@racwa/react-components";
import { CardChoiceInput, trackCustomFormotivInput, useFormotiv } from "raci-react-library";
import { FormProvider } from "react-hook-form";
import { CallToActionButton } from "../../shared/components/CallToActionButton";
import FaqCard from "../../shared/components/FaqCard";
import { FORMOTIV_CONFIG } from "../../shared/constants";
import { FormProps } from "./types";

const selectedBoatTypeName = "selectedBoatType";

export const Form: React.FC<FormProps> = ({ form, options, defaultBoatType, onSubmit, onSelectionChange }) => {
  const { handleSubmit } = form;
  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(formotivOnSubmitWrapper(onSubmit))} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2" id="header" data-testid="header">
              Type of boat
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid item>
              <CardChoiceInput
                name={selectedBoatTypeName}
                sublabel="Please choose an option."
                variant={isMobile ? "landscape" : "portrait"}
                alignItems="left"
                options={options}
                errorLabel="Please select one"
                onChange={(option) => {
                  trackCustomFormotivInput(selectedBoatTypeName, "radio", option.value);
                  onSelectionChange(option);
                  gtm(fieldTouched("Type of boat"));
                }}
              />
            </Grid>
            <CallToActionButton type="submit" data-testid="submit" color="primary" variant="contained" fullWidth>
              Next
            </CallToActionButton>
            <FaqCard />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Form;
