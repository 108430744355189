import { Grid, Link, useMediaQuery } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { theme } from "@racwa/react-components";
import { FaqContent, FaqHeading, StyledCard } from "./styles";

const FaqCard: React.FC = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container>
      <StyledCard background="white" sx={{ marginBottom: isMobile ? "68px" : "" }}>
        <Grid container>
          <Grid item xs={12}>
            <FaqHeading gutterBottom paragraph id="faq-header" data-testid="faq-header">
              Frequently asked questions
            </FaqHeading>
            <FaqContent paragraph variant="body2" id="faq-body" data-testid="faq-body">
              See our{" "}
              <Link
                href={process.env.REACT_APP_FAQ_URL}
                target="_blank"
                onClick={() => gtm(event("FAQs"))}
                id="faq-link"
                rel="noreferrer noopener"
              >
                FAQs
              </Link>{" "}
              on boat insurance.
            </FaqContent>
          </Grid>
        </Grid>
      </StyledCard>
    </Grid>
  );
};

export default FaqCard;
