import { TimeoutDialog, useGetSessionState } from "raci-react-library";
import { useNavigate } from "react-router-dom";
import { MoreBoatState } from "../../../views/MoreBoat/types";
import { atomTimeoutTime } from "../../atoms";
import { useResetTimeout } from "../../hooks/useResetTimeout";
import { QuoteRoutes, UTILITY_SESSION_TIMEOUT_PAGE_URL } from "../../routing/routes.config";

export const SessionTimeoutDialog = () => {
  const resetTimeout = useResetTimeout();
  const navigate = useNavigate();
  const { quote } = useGetSessionState<MoreBoatState>(QuoteRoutes.MoreBoat);

  return (
    <TimeoutDialog
      data-testid="session-timeout-dialog"
      timeoutAtom={atomTimeoutTime}
      resetExpiryTime={() => resetTimeout()}
      onExpiry={() => navigate(UTILITY_SESSION_TIMEOUT_PAGE_URL, { state: { quote: quote?.quoteNumber } })}
    />
  );
};
