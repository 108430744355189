import { useRecoilValue } from "recoil";
import { boatMakesAtom, claimTypesAtom, financiersAtom, hullTypesAtom, motorTypesAtom, suburbsAtom } from "../../atoms";

export const useReferenceData = () => {
  const claimTypes = useRecoilValue(claimTypesAtom);
  const motorTypes = useRecoilValue(motorTypesAtom);
  const hullTypes = useRecoilValue(hullTypesAtom);
  const suburbs = useRecoilValue(suburbsAtom);
  const financiers = useRecoilValue(financiersAtom);
  const boatMakes = useRecoilValue(boatMakesAtom);

  return {
    claimTypes,
    motorTypes,
    hullTypes,
    suburbs,
    financiers,
    boatMakes,
  };
};

export default useReferenceData;
