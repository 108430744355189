import { event, gtm } from "@racwa/analytics";
import { BoatPower, BoatSail } from "@racwa/react-components";
import { CardChoiceOption, useSessionState } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { BoatType } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { QUOTE_YOUR_BOAT_PAGE_URL } from "../../../../shared/routing/routes.config";
import { BoatTypeState, FormProps } from "../../types";

export const options: CardChoiceOption[] = [
  {
    key: BoatType.PowerBoat,
    value: BoatType.PowerBoat,
    label: "Powerboat",
    sublabel: "Runabout, dinghy, bow rider, bass boat, cuddy cabin, ski boat, cabin cruiser, centre console boat",
    icon: <BoatPower />,
  },
  {
    key: BoatType.SailBoat,
    value: BoatType.SailBoat,
    label: "Sailboat",
    sublabel: "Off-beach craft, trailer sailer, trailed yacht",
    icon: <BoatSail />,
  },
];

const useBoatType = (): FormProps => {
  const navigate = useNavigate();
  const [boatTypeState, setBoatTypeState] = useSessionState<BoatTypeState>();

  const onSubmit = (values: BoatTypeState) => {
    return new Promise<void>(() => {
      setBoatTypeState({
        selectedBoatType: values.selectedBoatType,
        isCompleted: true,
      });
      navigate(QUOTE_YOUR_BOAT_PAGE_URL);
    });
  };

  const form = useForm<BoatTypeState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: boatTypeState,
  });

  const onSelectionChange = (option: CardChoiceOption) => {
    gtm(event(option.label));
  };

  return {
    form,
    options,
    onSubmit,
    onSelectionChange,
  };
};

export default useBoatType;
