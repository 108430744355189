import { faCheck, faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonProps } from "@mui/material";
import { event, gtm } from "@racwa/analytics";

export interface SendQuoteButtonProps extends ButtonProps {
  emailSent: boolean;
}

export const SendQuoteButton: React.FC<SendQuoteButtonProps> = ({ emailSent, onClick, ...props }) => {
  const emailQuoteButtonId = `button-email-quote`;
  return (
    <Button
      type="button"
      id={emailQuoteButtonId}
      data-testid={emailQuoteButtonId}
      color="secondary"
      variant="contained"
      fullWidth
      onClick={(e) => {
        onClick?.(e);
        !emailSent && gtm(event("Send quote"));
      }}
      startIcon={
        <FontAwesomeIcon icon={emailSent ? faCheck : faEnvelopeOpenText} style={{ fontSize: "1.25rem !important" }} />
      }
      {...props}
    >
      {emailSent ? "Quote sent" : "Send quote"}
    </Button>
  );
};

export default SendQuoteButton;
