import { lazy } from "react";
import AboutYou from "../../views/AboutYou";
import BoatType from "../../views/BoatType";
import Confirmation from "../../views/Confirmation";
import ImportantInformation from "../../views/ImportantInformation";
import LetsStart from "../../views/LetsStart";
import MoreBoat from "../../views/MoreBoat";
import Payment from "../../views/Payment";
import RetrieveQuote from "../../views/RetrieveQuote";
import StartDate from "../../views/StartDate";
import YourBoat from "../../views/YourBoat";
import YourDetails from "../../views/YourDetails";
import YourQuote from "../../views/YourQuote";
import YourRegistration from "../../views/YourRegistration";
const SessionTimeout = lazy(() => import("../../views/SessionTimeout"));
const SystemUnavailable = lazy(() => import("../../views/SystemUnavailable"));

export enum QuoteRoutes {
  LetsStart = "lets-start",
  AboutYou = "about-you",
  BoatType = "boat-type",
  YourBoat = "your-boat",
  MoreBoat = "more-about-your-boat",
  YourQuote = "your-quote",
}

export enum UtilityRoutes {
  ImportantInformation = "important-information",
  SessionTimeout = "408",
  SyncError = "sync-error",
  SystemUnavailable = "error",
  RetrieveQuote = "retrieve-quote",
}

export enum PurchaseRoutes {
  StartDate = "start-date",
  YourDetails = "your-details",
  YourRegistration = "your-rego",
  Payment = "payment",
  Confirmation = "confirmation",
}

export enum QuotePageNames {
  ImportantInformation = "Important information",
  LetsStart = "Let's start",
  AboutYou = "About you",
  BoatType = "Boat type",
  YourBoat = "Your boat",
  MoreBoat = "More about your boat",
  YourQuote = "Your quote",
}

export enum PurchasePageNames {
  StartDate = "Start date",
  YourDetails = "Your details",
  YourRegistration = "Your rego",
  Payment = "Payment",
  Confirmation = "Confirmation",
  SyncError = "Sync error",
}

export enum UtilityPageNames {
  ImportantInformation = "Important information",
  SessionTimeout = "Session timed out",
  SyncError = "Sync error",
  SystemUnavailable = "System unavailable",
  RetrieveQuote = "Retrieve quote",
}

export enum UtilityPageHeadings {
  ImportantInformation = "Important information",
  SessionTimeout = "Uh oh!",
  SyncError = "Uh oh!",
  SystemUnavailable = "Uh oh!",
  Default = "Uh oh!",
  RetrieveQuote = "Welcome back",
}

const getRouteUrl = (route: string) => `${process.env.PUBLIC_URL}/${route}`;

export const QUOTE_LETS_START_PAGE_URL = getRouteUrl(QuoteRoutes.LetsStart);
export const QUOTE_ABOUT_YOU_PAGE_URL = getRouteUrl(QuoteRoutes.AboutYou);
export const QUOTE_BOAT_TYPE_PAGE_URL = getRouteUrl(QuoteRoutes.BoatType);
export const QUOTE_YOUR_BOAT_PAGE_URL = getRouteUrl(QuoteRoutes.YourBoat);
export const QUOTE_MORE_BOAT_PAGE_URL = getRouteUrl(QuoteRoutes.MoreBoat);
export const QUOTE_YOUR_QUOTE_PAGE_URL = getRouteUrl(QuoteRoutes.YourQuote);

export const PURCHASE_START_DATE_PAGE_URL = getRouteUrl(PurchaseRoutes.StartDate);
export const PURCHASE_YOUR_DETAILS_PAGE_URL = getRouteUrl(PurchaseRoutes.YourDetails);
export const PURCHASE_YOUR_REGISTRATION_PAGE_URL = getRouteUrl(PurchaseRoutes.YourRegistration);
export const PURCHASE_PAYMENT_PAGE_URL = getRouteUrl(PurchaseRoutes.Payment);
export const PURCHASE_CONFIRMATION_PAGE_URL = getRouteUrl(PurchaseRoutes.Confirmation);

export const UTILITY_IMPORTANT_INFORMATION_URL = getRouteUrl(UtilityRoutes.ImportantInformation);
export const UTILITY_SESSION_TIMEOUT_PAGE_URL = getRouteUrl(UtilityRoutes.SessionTimeout);
export const UTILITY_SYNC_ERROR_PAGE_URL = getRouteUrl(UtilityRoutes.SyncError);
export const UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL = getRouteUrl(UtilityRoutes.SystemUnavailable);
export const UTILITY_SYSTEM_UNAVAILABLE_WITH_ERROR_CODE_PAGE_URL = `${UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL}/:errorCode`;
export const UTILITY_RETRIEVE_QUOTE_URL = getRouteUrl(UtilityRoutes.RetrieveQuote);

export interface RouteInformation {
  key: string;
  path: string;
  name: string;
  element: JSX.Element;
  heading?: string;
}

export const quoteRoutesInfo: Record<QuoteRoutes, RouteInformation> = {
  [QuoteRoutes.LetsStart]: {
    key: QuoteRoutes.LetsStart,
    path: QUOTE_LETS_START_PAGE_URL,
    name: QuotePageNames.LetsStart,
    element: <LetsStart />,
  },
  [QuoteRoutes.AboutYou]: {
    key: QuoteRoutes.AboutYou,
    path: QUOTE_ABOUT_YOU_PAGE_URL,
    name: QuotePageNames.AboutYou,
    element: <AboutYou />,
  },
  [QuoteRoutes.BoatType]: {
    key: QuoteRoutes.BoatType,
    path: QUOTE_BOAT_TYPE_PAGE_URL,
    name: QuotePageNames.BoatType,
    element: <BoatType />,
  },
  [QuoteRoutes.YourBoat]: {
    key: QuoteRoutes.YourBoat,
    path: QUOTE_YOUR_BOAT_PAGE_URL,
    name: QuotePageNames.YourBoat,
    element: <YourBoat />,
  },
  [QuoteRoutes.MoreBoat]: {
    key: QuoteRoutes.MoreBoat,
    path: QUOTE_MORE_BOAT_PAGE_URL,
    name: QuotePageNames.MoreBoat,
    element: <MoreBoat />,
  },
  [QuoteRoutes.YourQuote]: {
    key: QuoteRoutes.YourQuote,
    path: QUOTE_YOUR_QUOTE_PAGE_URL,
    name: QuotePageNames.YourQuote,
    element: <YourQuote />,
  },
};

export const purchaseRoutesInfo: Record<PurchaseRoutes, RouteInformation> = {
  [PurchaseRoutes.StartDate]: {
    key: PurchaseRoutes.StartDate,
    path: PURCHASE_START_DATE_PAGE_URL,
    name: PurchasePageNames.StartDate,
    element: <StartDate />,
  },
  [PurchaseRoutes.YourDetails]: {
    key: PurchaseRoutes.YourDetails,
    path: PURCHASE_YOUR_DETAILS_PAGE_URL,
    name: PurchasePageNames.YourDetails,
    element: <YourDetails />,
  },
  [PurchaseRoutes.YourRegistration]: {
    key: PurchaseRoutes.YourRegistration,
    path: PURCHASE_YOUR_REGISTRATION_PAGE_URL,
    name: PurchasePageNames.YourRegistration,
    element: <YourRegistration />,
  },
  [PurchaseRoutes.Payment]: {
    key: PurchaseRoutes.Payment,
    path: PURCHASE_PAYMENT_PAGE_URL,
    name: PurchasePageNames.Payment,
    element: <Payment />,
  },
  [PurchaseRoutes.Confirmation]: {
    key: PurchaseRoutes.Confirmation,
    path: PURCHASE_CONFIRMATION_PAGE_URL,
    name: PurchasePageNames.Confirmation,
    element: <Confirmation />,
  },
};

export const utilityRoutes: RouteInformation[] = [
  {
    key: UtilityRoutes.ImportantInformation,
    path: UTILITY_IMPORTANT_INFORMATION_URL,
    name: UtilityPageNames.ImportantInformation,
    element: <ImportantInformation />,
    heading: UtilityPageHeadings.ImportantInformation,
  },
  {
    key: UtilityRoutes.SessionTimeout,
    path: UTILITY_SESSION_TIMEOUT_PAGE_URL,
    name: UtilityPageNames.SessionTimeout,
    element: <SessionTimeout />,
    heading: UtilityPageHeadings.SessionTimeout,
  },
  {
    key: UtilityRoutes.SystemUnavailable,
    path: UTILITY_SYSTEM_UNAVAILABLE_WITH_ERROR_CODE_PAGE_URL,
    name: UtilityPageNames.SystemUnavailable,
    element: <SystemUnavailable />,
    heading: UtilityPageHeadings.SystemUnavailable,
  },
  {
    key: UtilityRoutes.SystemUnavailable,
    path: UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
    name: UtilityPageNames.SystemUnavailable,
    element: <SystemUnavailable />,
    heading: UtilityPageHeadings.SystemUnavailable,
  },
  {
    key: UtilityRoutes.RetrieveQuote,
    path: UTILITY_RETRIEVE_QUOTE_URL,
    name: UtilityPageNames.RetrieveQuote,
    element: <RetrieveQuote />,
    heading: UtilityPageHeadings.RetrieveQuote,
  },
];

export const quoteRoutes: RouteInformation[] = Object.values(quoteRoutesInfo);
export const purchaseRoutes: RouteInformation[] = Object.values(purchaseRoutesInfo);
export const quoteToPurchaseRoutes: RouteInformation[] = [...quoteRoutes, ...purchaseRoutes];
export const allRoutes = [...quoteRoutes, ...purchaseRoutes, ...utilityRoutes];

export interface UseRoutesResult {
  currentStep: number;
  totalSteps: number;
  onBack: () => void;
}
