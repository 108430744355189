import { event, fieldTouched, gtm } from "@racwa/analytics";
import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import AgreedValue from "../../../../shared/components/AgreedValue";
import AgreedValueCard from "../../../../shared/components/AgreedValue/components/AgreedValueCard";

const agreedValueName = "agreedValue";

export const BoatValue: React.FC = () => {
  const { control } = useFormContext();
  const agreedValue = useWatch({ control: control, name: "agreedValue" });

  const [prevAgreedValue, setPrevAgreedValue] = useState(agreedValue);
  if (agreedValue !== prevAgreedValue && agreedValue > 150000) {
    setPrevAgreedValue(agreedValue);
    gtm(event("Please enter a value under $150,000"));
  }

  return (
    <>
      <AgreedValue
        id={agreedValueName}
        name={agreedValueName}
        onBlur={(_) => gtm(fieldTouched("Value of boat"))}
        sxProps={{ marginTop: "0px" }}
      />
      {Number(agreedValue) > 150000 && <AgreedValueCard />}
    </>
  );
};

export default BoatValue;
