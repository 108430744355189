import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { Divider, Grid, Typography } from "@mui/material";
import { FadeWiggleIcon, FinePrint, theme } from "@racwa/react-components";
import { currency } from "@racwa/utils";
import { MyRacAccountDisplay } from "raci-react-library";
import { StyledGridContainer } from "../../styles";

export interface SuccessfulConfirmationProps {
  firstName?: string | null;
  policyNumber?: string;
  total?: number;
  receiptNumber?: string | null;
  inline?: boolean;
}

export const iconStyles = {
  height: "101px",
  width: "100%",
  color: theme.palette.secondary.main,
} satisfies FontAwesomeIconProps["style"];

const SuccessfulConfirmation: React.FC<SuccessfulConfirmationProps> = ({
  firstName,
  policyNumber,
  total = 0,
  receiptNumber,
  inline = false,
}) => {
  const totalValue = () => {
    const amount = currency.format(total);

    return (
      <>
        {receiptNumber ? "" : " | "}Paid {amount}
      </>
    );
  };

  const policyLine = () => {
    return (
      <FinePrint paragraph data-testid="policyNumberLabel">
        Policy number {policyNumber}
      </FinePrint>
    );
  };

  const receiptLine = () => {
    return (
      <>
        {receiptNumber && (
          <FinePrint paragraph data-testid="receiptNumberLabel">
            {totalValue()} | Receipt number [{receiptNumber}]
          </FinePrint>
        )}
      </>
    );
  };

  return (
    <StyledGridContainer container justifyContent="center" alignItems="center">
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <FadeWiggleIcon icon={faThumbsUp} style={iconStyles} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" id="header" data-testid="header" data-hj-suppress>
            Welcome aboard, {firstName}!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" id="subHeader" data-testid="subHeader">
            <b>You'll receive an email with your policy documents shortly.</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {policyLine()}
          {receiptLine()}
        </Grid>
      </Grid>
      <Divider />
      <MyRacAccountDisplay
        myRacLogInUrl={process.env.REACT_APP_MY_RAC_LOG_IN!}
        homePageUrl={process.env.REACT_APP_RAC_HOME_PAGE!}
        inline={inline}
      />
    </StyledGridContainer>
  );
};

export default SuccessfulConfirmation;
