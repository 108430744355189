import { RacwaCardNotification } from "@racwa/react-components";
import { InsurancePhoneNumberLink } from "raci-react-library";
import { StyledNotificationTypography } from "../../../styled";

const HighValueCard = () => {
  return (
    <RacwaCardNotification id="high-value-notification" title="Sorry, we can't help you online" severity="warning">
      <StyledNotificationTypography variant="body2" id="high-value-notification-body-1">
        Please call us on <InsurancePhoneNumberLink id="high-value-notification-link-1" /> to discuss your quote.
      </StyledNotificationTypography>
    </RacwaCardNotification>
  );
};

export default HighValueCard;
