import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { isAgentFetchingAtom } from "../../atoms";
import { allRoutes } from "../../routing/routes.config";
import useApiClient from "../useApiClient";

export const useFindAgents = (): boolean => {
  const client = useApiClient();
  const location = useLocation();
  const [availableAgents, setAvailableAgents] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useRecoilState(isAgentFetchingAtom);

  useEffect(() => {
    const findAgents = async () => {
      try {
        setIsFetching(true);
        const response = await client.getwebchatcontactid();
        if (response.result.pointOfContactId !== "") {
          setAvailableAgents(true);
        } else {
          setAvailableAgents(false);
        }
      } catch {
        setAvailableAgents(false);
      } finally {
        setIsFetching(false);
      }
    };

    if (allRoutes.find((route) => route.path === location.pathname) && !isFetching) findAgents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return availableAgents;
};

export default useFindAgents;
