import { SessionTimeoutDialog } from "../../shared/components/SessionTimeoutDialog";
import Form from "./Form";
import useLetsStart from "./hooks/useLetsStart";

export const LetsStart: React.FC = () => {
  const props = useLetsStart();

  return (
    <>
      <Form {...props} />
      <SessionTimeoutDialog />
    </>
  );
};

export default LetsStart;
