import { Grid, Typography } from "@mui/material";
import { InsurancePhoneNumberLink, PersonalInformation, useFormotiv } from "raci-react-library";
import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { CallToActionButton } from "../../shared/components/CallToActionButton";
import FaqCard from "../../shared/components/FaqCard";
import { FORMOTIV_CONFIG } from "../../shared/constants";
import { ErrorTypes, FormProps } from "./types";

export const TooltipTitle = "Mailing address";
export const TooltipMessage = (
  <Typography>
    Please enter the address where you prefer to receive mail. If it doesn't appear, try entering fewer details (e.g.
    remove the unit number). If you can't find your address please call us on{" "}
    <InsurancePhoneNumberLink id={"mailing-address-tooltip-phone-number-link"} /> with your quote number.
  </Typography>
);

export const Form: React.FC<FormProps> = ({
  form,
  onSubmit,
  findAddressErrorType,
  onAddressSelection,
  addressResults,
  onAddressInputChange,
  resetError,
}) => {
  const { handleSubmit, setError } = form;
  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);

  useEffect(() => {
    if (findAddressErrorType === ErrorTypes.NoMatch) {
      setError("mailingAddress", {});
    }
  }, [findAddressErrorType, setError]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(formotivOnSubmitWrapper(onSubmit))} action="#">
        <PersonalInformation
          pageTitle="Your details"
          onAddressSelection={onAddressSelection}
          addressResults={addressResults}
          onAddressInputChange={onAddressInputChange}
          showTitleGender={true}
          showFirstName={true}
          showLastName={true}
          showContactNumber={true}
          showEmail={true}
        />
        <Grid item xs={12} sx={{ marginTop: "8px" }}>
          <CallToActionButton type="submit" data-testid="submit" color="primary" variant="contained" fullWidth>
            Next
          </CallToActionButton>
          <FaqCard />
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Form;
