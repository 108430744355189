import { MenuItem } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { RacwaSelect } from "@racwa/react-components";
import { currencyFormatter, trackCustomFormotivInput } from "raci-react-library";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { YourQuoteState } from "../../../../types";

export interface ExcessInputProps {
  name: keyof YourQuoteState;
  id: string;
  defaultValue: number;
  excessOptions: number[];
  showLowerExcessMessage: boolean;
  onChange?: (answers: YourQuoteState) => void;
}

export const ExcessInput: React.FC<ExcessInputProps> = ({
  name,
  id,
  defaultValue,
  excessOptions,
  showLowerExcessMessage,
  onChange,
}) => {
  const { control, setValue, getValues, trigger } = useFormContext<YourQuoteState>();

  useEffect(() => {
    trigger();
  }, [showLowerExcessMessage, trigger]);

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      aria-label={id}
      rules={{
        required: { value: true, message: "Please select one" },
        validate: { checkExcess: () => !showLowerExcessMessage || "Please select a lower excess" },
      }}
      render={({ field: { value, ref, onChange: onComponentChange, onBlur, ...props }, fieldState: { error } }) => (
        <RacwaSelect
          id={id}
          defaultValue={defaultValue}
          onChange={async (e) => {
            const excess = e.target.value as string;
            trackCustomFormotivInput(name, "select", value);
            onComponentChange && onComponentChange(excess);
            value && setValue(props.name, excess);
            const request = {
              ...getValues(),
              excess: e.target.value,
            };
            onChange && onChange(request as YourQuoteState);
          }}
          onOpen={() => gtm(fieldTouched("Excess"))}
          error={!!error}
          helperText={error?.message || ""}
          inputRef={ref}
        >
          {excessOptions?.map((value, index) => {
            return (
              <MenuItem key={index} value={value}>
                {currencyFormatter(value, { stripZeroCents: true, withDecimals: false })}
              </MenuItem>
            );
          })}
        </RacwaSelect>
      )}
    />
  );
};
