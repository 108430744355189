import { Button, Grid, Link, Typography } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { useState } from "react";
import { StyledGrid } from "./styled";
import { FormProps, ImportantInformationState } from "./types";

export const ImportantInformationForm: React.FC<FormProps> = ({ values, onSubmit }) => {
  const [importantInformationState] = useState<ImportantInformationState>();

  return (
    <Grid container justifyContent="center" alignItems="center">
      <StyledGrid container>
        <Grid item xs={12}>
          <Typography id="bodytext-p1" sx={{ marginBottom: "24px" }}>
            You must answer our questions honestly, accurately and to the best of your knowledge. This duty applies to
            you and anyone else insured under the policy. If you answer for another person, we'll treat your answers as
            theirs. Your duty continues until we insure you. If you don't meet this duty, we may cancel your policy or
            treat it as if it never existed. Your claim may also be rejected or not paid in full.
          </Typography>

          <Typography id="bodytext-p2" sx={{ marginBottom: "24px" }}>
            By selecting 'Continue', you agree we've provided you with the{" "}
            <Link
              href={process.env.REACT_APP_PDS_PED_URL}
              rel="noreferrer noopener"
              target="_blank"
              onClick={() => gtm(event("Product Disclosure Statement"))}
            >
              Product Disclosure Statement
            </Link>
            . You also agree with our{" "}
            <Link
              href={process.env.REACT_APP_RAC_ABOUT_PRIVACY_PAGE}
              rel="noreferrer noopener"
              target="_blank"
              onClick={() => gtm(event("Privacy Policy"))}
            >
              Privacy Policy.
            </Link>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            id="button"
            type="button"
            data-testid="continue"
            color="primary"
            variant="contained"
            fullWidth
            style={{ marginTop: "24px", marginBottom: "40px" }}
            onClick={() => onSubmit({ ...importantInformationState, importantInformationConfirmed: true })}
          >
            Continue
          </Button>
        </Grid>
      </StyledGrid>
    </Grid>
  );
};

export default ImportantInformationForm;
