import { useGetSessionState } from "raci-react-library";
import { useForm } from "react-hook-form";
import { PurchaseRoutes } from "../../../shared/routing/routes.config";
import { PaymentData } from "../../Payment/types";
import { YourDetailsState } from "../../YourDetails/types";
import { FormProps, Values } from "../types";

export const useConfirmation = (): FormProps => {
  const { firstName } = useGetSessionState<YourDetailsState>(PurchaseRoutes.YourDetails);
  const paymentData = useGetSessionState<PaymentData>(PurchaseRoutes.Payment);

  const form = useForm<Values>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: paymentData,
  });

  return {
    form,
    data: {
      isSuccessful: paymentData.isSuccessful,
      firstName: firstName,
      policyNumber: paymentData.policyNumber,
      receiptNumber: paymentData.receiptNumber,
      total: paymentData.total,
      lockdownPreviousPage: true,
    },
  };
};

export default useConfirmation;
