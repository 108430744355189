import { Claim, MultiChoiceOption } from "raci-react-library";
import { RetrieveQuoteResponse, SecuritySystems } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import useReferenceData from "../../../../shared/hooks/useReferenceData";
import { useUpdateSessionState } from "../../../../shared/hooks/useUpdateSessionState";
import { toMultiChoice } from "../../../../shared/utils/helpers";
import { options } from "../../../BoatType/hooks/useBoatType";

export const useUpdateRetrieveQuoteState = () => {
  const updateSessionState = useUpdateSessionState();
  const { suburbs, boatMakes, financiers } = useReferenceData();

  return (state: RetrieveQuoteResponse) => {
    updateSessionState({
      type: "important-information",
      payload: {
        importantInformationConfirmed: true,
        isCompleted: true,
      },
    });

    updateSessionState({
      type: "lets-start",
      payload: {
        waterStored: false,
        commercialUse: false,
        highValue: false,
        personalWatercraft: false,
        isCompleted: true,
      },
    });

    updateSessionState({
      type: "about-you",
      payload: {
        dateOfBirth: state.dateOfBirth,
        skippersExperience: state.skipperExperience,
        numberOfClaims: (state.claimsHistory?.reduce((acc, i) => acc + i.numberOfClaims, 0) ?? 0).toString(),
        claimsHistory: state.claimsHistory?.reduce((acc, i) => {
          return [
            ...acc,
            ...i.claimedYears.map((year) => ({ year: year.toString(), externalCode: i.claimType.valueOf() })),
          ];
        }, [] as Claim[]),
        isCompleted: true,
      },
    });

    updateSessionState({
      type: "boat-type",
      payload: {
        selectedBoatType: options.find((e) => e.value === state.boatType)?.value,
        isCompleted: true,
      },
    });

    updateSessionState({
      type: "your-boat",
      payload: {
        make: boatMakes.find((i) => i.externalCode === state.make),
        otherMake: state.otherMake,
        financed: state.isFinanced,
        financier: financiers.find((i) => i.externalFinancierNumber === state.financeCompanies?.[0]),
        year: state.builtYear,
        hullConstruction: state.hullConstruction,
        agreedValue: state.agreedValue,
        isCompleted: true,
      },
    });

    const securitySystemOptions = toMultiChoice(SecuritySystems).reduce(
      (acc, i) => {
        acc[i.key as keyof typeof SecuritySystems] = i;
        return acc;
      },
      {} as Record<keyof typeof SecuritySystems, MultiChoiceOption>,
    );

    updateSessionState({
      type: "more-about-your-boat",
      payload: {
        quote: {
          cover: state.cover,
          startDate: state.startDate,
          quoteNumber: state.quoteNumber,
        },
        suburb: suburbs.find(
          (x) => x.postcode === state.postcode.toString() && x.name.toLowerCase() === state.suburb.toLowerCase(),
        ),
        securitySystems: [
          state.isSecurityAlarmGps ? securitySystemOptions["Alarm_or_GPS_tracker"] : undefined,
          state.isSecurityNebo ? securitySystemOptions["Nebolink"] : undefined,
          state.isSecurityHitch ? securitySystemOptions["Trailer_security_device"] : undefined,
          !state.isSecurityAlarmGps && !state.isSecurityNebo && !state.isSecurityHitch
            ? securitySystemOptions["No_security"]
            : undefined,
        ].filter(Boolean) as MultiChoiceOption[],
        motorType: state.motorType,
        keptInGarage: state.isGaraged,
        isCompleted: true,
      },
    });

    updateSessionState({
      type: "your-quote",
      payload: {
        agreedValue: state.agreedValue,
        excess: state.cover.excess.value,
        racingCover: state.isRacingCover,
        flotationCover: state.isFlotationCover,
      },
    });

    updateSessionState({
      type: "start-date",
      payload: {
        policyStartDate: state.startDate.value,
      },
    });

    updateSessionState({
      type: "your-details",
      payload: {
        email: state.email,
        title: state.title,
        gender: state.gender,
        lastName: state.surname,
        firstName: state.firstName,
        contactNumber: state.contactNumber,
      },
    });

    let knowBoatRego = undefined;
    if (state.boatRegistration != null) {
      knowBoatRego = state.boatRegistration !== "";
    }
    let knowTrailerRego = undefined;
    if (state.trailerRegistration != null) {
      knowTrailerRego = state.trailerRegistration !== "";
    }

    updateSessionState({
      type: "your-registration",
      payload: {
        knowBoatRegistration: knowBoatRego,
        boatRego: state.boatRegistration,
        knowTrailerRegistration: knowTrailerRego,
        trailerRego: state.trailerRegistration,
      },
    });
  };
};

export default useUpdateRetrieveQuoteState;
