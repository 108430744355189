import { useMediaQuery } from "@mui/material";
import { theme } from "@racwa/react-components";
import { useFormotiv } from "raci-react-library";
import React, { useEffect } from "react";
import { FORMOTIV_CONFIG } from "../../shared/constants";
import SuccessfulConfirmation from "./components/SuccessfulConfirmation";
import UnsuccessfulConfirmation from "./components/UnsuccessfulConfirmation";
import { StyledContainer } from "./styles";
import { FormProps } from "./types";

export const Form: React.FC<FormProps> = ({ data: { isSuccessful, ...data } }) => {
  const { initialiseFormotiv } = useFormotiv(FORMOTIV_CONFIG);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (isSuccessful) {
      initialiseFormotiv();
      window.formotivConfig.state.ptv3 = data.policyNumber;
      window.formotiv.submitFinal(window.formotivConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.policyNumber, isSuccessful]);

  return (
    <StyledContainer>
      {isSuccessful ? (
        <SuccessfulConfirmation
          firstName={data.firstName}
          policyNumber={data.policyNumber}
          receiptNumber={data.receiptNumber}
          total={data.total}
          inline={!isMobile}
        />
      ) : (
        <UnsuccessfulConfirmation policyNumber={data.policyNumber} inline={!isMobile} />
      )}
    </StyledContainer>
  );
};

export default Form;
