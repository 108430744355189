import { useSessionState, useSetBackdrop } from "raci-react-library";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useReferenceData from "../../../shared/hooks/useReferenceData";
import { QUOTE_MORE_BOAT_PAGE_URL, QuoteRoutes } from "../../../shared/routing/routes.config";
import { YourQuoteState } from "../../YourQuote/types";
import { FormProps, YourBoatState } from "../types";

export const useYourBoat = (): FormProps => {
  const navigate = useNavigate();
  const setIsOpen = useSetBackdrop();
  const [state, setState] = useSessionState<YourBoatState>();
  const { financiers, hullTypes, boatMakes } = useReferenceData();
  const [quoteState, setYourQuoteState] = useSessionState<YourQuoteState>({
    specificKey: QuoteRoutes.YourQuote,
    skipPageTrackingRecalculation: true,
  });

  useEffect(() => {
    if (hullTypes.length < 1 || boatMakes.length < 1 || financiers.length < 1) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
    return () => setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hullTypes, boatMakes, financiers]);

  const form = useForm<YourBoatState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: state,
    shouldUnregister: true,
  });

  const onSubmit = (value: YourBoatState) => {
    return new Promise<void>(() => {
      setYourQuoteState({ ...quoteState, agreedValue: value.agreedValue });
      setState({ ...value, isCompleted: true });
      navigate(QUOTE_MORE_BOAT_PAGE_URL);
    });
  };

  return {
    form,
    data: { financiers, hullTypes, boatMakes },
    onSubmit,
  };
};
